import React, { Fragment } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Typography, Link, Grid } from '@mui/material';

import Background from '../../resources/images/WomanWithIpad.jpg';
import HorizontalLogoWithSlogan from '../ui/horizontalLogoWithSlogan';
import Slogan from '../ui/other/slogan';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://fairtreeprivateclient.co.za/">
        Fairtree Private Client
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  background: {
    background: `url(${Background})`,
    backgroundSize: 'cover',
    backgroundPosition: 'left',
    height: '100vh'
  },
  signInBackground: {
    padding: theme.spacing(1, 4, 2, 2),
    background: `${
      theme.palette.mode === 'dark'
        ? 'linear-gradient(to right, rgba(35,32,31,1) 30%, rgba(35,32,31, 1) 70%, rgba(35,32,31, 0.8) 80%, rgba(35,32,31, 0) 100%)'
        : 'linear-gradient(to right, rgba(222,218,212,1) 30%, rgba(222,218,212, 1) 70%, rgba(222,218,212, 0.8) 80%, rgba(222,218,212, 0) 100%)'
    }`
  },
  slogan: {
    margin: theme.spacing(1, 4, 2, 5)
  }
}));

export default function LoginLayout({ children, ...props }) {
  const classes = useStyles();

  return (
    <Grid container className={classes.background}>
      <Grid
        item
        md={4}
        sm={6}
        xs={12}
        className={classes.signInBackground}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <HorizontalLogoWithSlogan />
        <Fragment>{children}</Fragment>
        <Slogan className={classes.slogan} />
      </Grid>
      <Grid item md={8} sm={6} xs={0}></Grid>
    </Grid>
  );
}
