import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import PrivateLayout from './privateLayout';

const PrivateRoute = (props) => {
  return localStorage.getItem('user') ? (
    <PrivateLayout>
      <Outlet />
    </PrivateLayout>
  ) : (
    <Navigate to={'/signin'} />
  );
};

export default PrivateRoute;
