import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, CardActions } from '@mui/material';
import { Box } from '@mui/system';

function CourseModuleTile(props) {
  const handleClick = () => {
    //Navigate to modules
    props.onClick();
  };

  return (
    <Card
      sx={{
        minWidth: 320,
        maxWidth: 320,
        // minHeight: 250,
        m: 2,
        display: 'inline-block',
        verticalAlign: 'top'
      }}
    >
      <CardActionArea
        sx={
          {
            // minHeight: 250,
          }
        }
        onClick={handleClick}
        disabled={!props.course.active}
      >
        <CardMedia
          component="img"
          height="180"
          image={props.course.banner}
          sx={{ objectFit: 'contain' }}
        />
        {!props.course.active && (
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              top: 0,
              width: '100%',
              bgcolor: 'rgba(0, 0, 0, 0.54)'
            }}
          ></Box>
        )}
        <CardContent>
          <Box display={'flex'} flexDirection={'column'}>
            {props.course.timeAllocated ? (
              <Typography gutterBottom variant="h7" component="div">
                {`Total Time : ${props.course.timeAllocated} minutes`}
              </Typography>
            ) : (
              <>
                <Typography gutterBottom variant="h7" component="div">
                  {props.course.name}
                </Typography>
                <Typography noWrap variant="body2" color="text.secondary">
                  {props.course.description}
                </Typography>
              </>
            )}
          </Box>
        </CardContent>
        <CardActions>
          {props.course.active ? (
            <Typography color="primary">Available</Typography>
          ) : (
            <Typography color="primary">Coming soon</Typography>
          )}
        </CardActions>
      </CardActionArea>
    </Card>
  );
}

export default CourseModuleTile;
