import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';

import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

import { useDispatch, useSelector } from 'react-redux';
import { logout as userLogout } from '../../features/auth/auth.slice';
import { Fragment } from 'react';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: '0 0 auto',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  usernameContianer: {}
}));

const ProfileNavigation = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState();
  const user = useSelector((state) => state.auth.user);

  const dispatch = useDispatch();

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const logout = () => {
    dispatch(userLogout());
  };

  return (
    <div className={classes.root}>
      <div className={classes.usernameContianer}>
        {user ? (
          <Fragment>
            <Typography lineHeight={1}>
              {user.firstName} {user.lastName}
            </Typography>
            <Typography variant="caption" lineHeight={1}>
              {user.email}
            </Typography>
          </Fragment>
        ) : null}
      </div>

      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpen}
        color="inherit"
        size="large"
      >
        <AccountCircle />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="menu-appbar"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={open}
        onClose={handleClose}
      >
        {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
            <MenuItem onClick={handleClose}>My account</MenuItem> */}
        <MenuItem component={NavLink} to={'/user/profile'}>
          Your Profile
        </MenuItem>
        <MenuItem onClick={logout}>Sign out</MenuItem>
      </Menu>
    </div>
  );
};

export default ProfileNavigation;
