import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setAlertSuccess, setAlertError } from '../alert/alert.slice';
import { authService } from './auth.service';
import LinkedInTag from 'react-linkedin-insight';
LinkedInTag.init('4483337', 'dc', false);

let user = JSON.parse(localStorage.getItem('user'));

// First, create the thunk
export const getAuth = createAsyncThunk('auth/getAuth', async () => {
  const response = await authService.auth();
  return { user: response };
});

export const login = createAsyncThunk('auth/login', async (credentials, thunkAPI) => {
  try {
    const response = await authService.login(credentials);
    if (response.success) {
      thunkAPI.dispatch(getAuth());
      thunkAPI.dispatch(setAlertSuccess('Logged in'));

      //LinkedIn fire event on login

      LinkedInTag.track(9439121);
      //window.lintrk("track", { conversion_id: 9439121 });
    }

    return { user: response };
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    thunkAPI.dispatch(setAlertError(message));
    return thunkAPI.rejectWithValue();
  }
});

export const logout = createAsyncThunk('auth/logout', async () => {
  await authService.logout();
});

export const register = createAsyncThunk('auth/register', async (user, thunkAPI) => {
  try {
    const response = await authService.register(user);

    if (response.success) {
      thunkAPI.dispatch(getAuth());
      thunkAPI.dispatch(setAlertSuccess('Successfuly registered.'));

      //LinkedIn fire event on register
      LinkedInTag.track(9439121);
      //window.lintrk("track", { conversion_id: 9439121 });
    }

    return { user: response };
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    thunkAPI.dispatch(setAlertError(message));
    return thunkAPI.rejectWithValue();
  }
});

export const forgotPassword = createAsyncThunk(
  'auth/forgotPassword',
  async (credentials, thunkAPI) => {
    const response = await authService.forgotPassword(credentials);
    if (response.success) {
      thunkAPI.dispatch(setAlertSuccess('Password reset email sent. Check your inbox.'));
    }

    return response;
  }
);

export const updatePassword = createAsyncThunk(
  'auth/updatePassword',
  async (credentials, thunkAPI) => {
    const response = await authService.updatePassword(credentials);

    if (response.success) {
      thunkAPI.dispatch(setAlertSuccess('Password reset!'));
    }

    return response;
  }
);

export const sendEmailVerification = createAsyncThunk('auth/sendEmailVerification', async () => {
  const response = await authService.sendEmailVerification();
  return response.data;
});

export const verifyEmailAddress = createAsyncThunk(
  'auth/verifyEmailAddress',
  async (token, thunkAPI) => {
    const response = await authService.verifyEmailAddress(token);

    if (response.success) {
      thunkAPI.dispatch(setAlertSuccess('Email verifeid!'));
    }

    return response;
  }
);

const initialState = user ? { loggedIn: true, user } : { loggedIn: false, user: null };

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  extraReducers: {
    [getAuth.pending]: (state) => {
      state.loading = true;
    },
    [getAuth.fulfilled]: (state, action) => {
      state.user = action.payload.user;
      state.loading = false;
    },

    [login.pending]: (state) => {
      state.loading = true;
    },
    [login.fulfilled]: (state, action) => {
      state.loading = false;
      state.user = action.payload.user;
      state.loggedIn = true;
    },
    [login.rejected]: (state) => {
      state.loading = false;
      state.user = null;
      state.loggedIn = false;
    },

    [logout.fulfilled]: (state) => {
      state.loading = false;
      state.user = null;
      state.loggedIn = false;
    },

    [register.pending]: (state) => {
      state.loading = true;
      state.loggedIn = false;
      state.user = null;
    },
    [register.fulfilled]: (state, action) => {
      state.loading = false;
      state.user = action.payload.user;
      state.loggedIn = true;
    },
    [register.rejected]: (state) => {
      state.loading = false;
      state.user = null;
      state.loggedIn = false;
    },

    [forgotPassword.pending]: (state) => {
      state.loading = true;
    },
    [forgotPassword.fulfilled]: (state) => {
      state.loading = false;
      state.loggedIn = false;
      state.success = true;
    },
    [forgotPassword.rejected]: (state) => {
      state.loading = false;
      state.loggedIn = false;
    },

    [updatePassword.pending]: (state) => {
      state.loading = true;
    },
    [updatePassword.fulfilled]: (state) => {
      state.loading = false;
      state.loggedIn = false;
      state.success = true;
    },
    [updatePassword.rejected]: (state) => {
      state.loading = false;
      state.loggedIn = false;
    },

    [verifyEmailAddress.pending]: (state) => {
      state.loading = true;
    },
    [verifyEmailAddress.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = action.payload.success;
    },
    [verifyEmailAddress.rejected]: (state) => {
      state.loading = false;
      state.success = false;
    }
  }
});

export default authSlice.reducer;
