import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import {
  getAllPlayLists,
  setSelectedPlaylist
} from '../../../features/educationalVideos/educationalVideos.slice';
import PlaylIstTile from './playListTile';
import { useTitle } from '../../../hoc/titleProvider';

function EducationalVideos() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const playlists = useSelector((state) => state.educationalVideos.educationalVideos);

  const { setTitle } = useTitle();
  setTitle('Investment Education Short Videos');

  useEffect(() => {
    dispatch(getAllPlayLists());
  }, []);

  const handleClick = (educationalVideos) => {
    dispatch(setSelectedPlaylist(educationalVideos));

    navigate('/playlist');
  };

  return (
    <>
      <Box>
        <Typography variant="h4" sx={{ m: 2 }}>
          Equip and empower yourself on different investment topics, by engaging with our
          short-video playlists which navigate a variety of investment concepts.
        </Typography>
        {playlists.map((playlist) => (
          <PlaylIstTile
            key={playlist._id}
            playlist={playlist}
            onClick={() => handleClick(playlist)}
          />
        ))}
      </Box>
    </>
  );
}

export default EducationalVideos;
