import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Box } from '@mui/system';

import PrimaryButton from '../../ui/buttons/primaryButton';
import Form from '../../ui/forms';
import Input from '../../ui/forms/fields/input';
import Select from '../../ui/forms/fields/select';
import GreatDatePicker from '../../ui/forms/fields/datePicker';
import { Grid, MenuItem, Typography } from '@mui/material';

import LinearProgressWithLabel from '../../ui/controls/linearProgressWithLabel';
import { useTitle } from '../../../hoc/titleProvider';
import { reset } from 'numeral';
import { getCourseProgress, getLoggedInUser, updateUser } from '../../../features/user/user.slice';
import { calculateProfileProgress } from '../../../utils/userHelpers';

const schema = yup.object().shape({
  firstName: yup.string().required('Please provide a first name'),
  lastName: yup.string().required('Please provide a last name'),
  phoneNumber: yup.string().required('Please enter your phone number.'),
  dob: yup.date().required('Please select a date of birth.'),
  gender: yup.string(),
  race: yup.string()
});

function Profile() {
  const { setTitle } = useTitle();
  setTitle('My Profile');

  const dispatch = useDispatch();

  const [progress, setProgress] = React.useState(10);
  const [editable, setEditable] = useState(false);

  const {
    reset,
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema)
  });

  const user = useSelector((state) => state.user.user);
  const courseProgress = useSelector((state) => state.user.courseProgress);

  useEffect(() => {
    dispatch(getLoggedInUser());
  }, []);

  useEffect(() => {
    if (user && user._id) dispatch(getCourseProgress(user._id));
  }, [user]);

  useEffect(() => {
    if (user && courseProgress) {
      setProgress(calculateProfileProgress(user, courseProgress.length));
      reset(user);
    }
  }, [user, courseProgress]);

  const onSubmit = (data) => {
    data.id = user._id;

    dispatch(updateUser(data))
      .then((originalPromiseResult) => {
        console.log(originalPromiseResult);
        setEditable(!editable);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleEdit = () => {
    if (editable) {
      handleSubmit(onSubmit)();
    } else setEditable(!editable);
  };

  return (
    <Grid container spacing={3}>
      <Grid item md={3}>
        <Typography variant="h2">
          {user && `${user.firstName || 'Welcome'} ${user.lastName || ''}`}
        </Typography>
        <Typography>{user && user.email}</Typography>
        <LinearProgressWithLabel value={progress} />
        {progress === 100 ? (
          <Typography>Well Done for completing your profile!</Typography>
        ) : (
          <Typography>Remember to complete your profile.</Typography>
        )}
      </Grid>
      <Grid item md={9}>
        <Typography variant="h2">Your Personal Details</Typography>
        <Form>
          <Input
            label="First Name"
            autoComplete="firstName"
            autoFocus
            error={!!errors.firstName}
            helperText={errors?.firstName?.message}
            disabled={!editable}
            {...register('firstName')}
          />
          <Input
            label="Last Name"
            autoComplete="lastName"
            error={!!errors.lastName}
            helperText={errors?.lastName?.message}
            disabled={!editable}
            {...register('lastName')}
          />
          <GreatDatePicker
            fullWidth
            id="dob"
            name="dob"
            label="Date of Birth"
            control={control}
            error={!!errors.dob}
            disabled={!editable}
          />

          <Input
            label="Phone Number (International Format)"
            autoComplete="phoneNumber"
            error={!!errors.phoneNumber}
            helperText={errors?.phoneNumber?.message}
            disabled={!editable}
            {...register('phoneNumber')}
          />

          <Select
            name="gender"
            label="Gender"
            error={!!errors.gender}
            helperText={errors?.gender?.message}
            disabled={!editable}
            control={control}>
            <MenuItem value="Male">Male</MenuItem>
            <MenuItem value="Female">Female</MenuItem>
          </Select>

          <Select
            name="race"
            label="Ethnicity"
            error={!!errors.race}
            helperText={errors?.race?.message}
            disabled={!editable}
            control={control}>
            <MenuItem value="Black">Black</MenuItem>
            <MenuItem value="White">White</MenuItem>
            <MenuItem value="Coloured">Coloured</MenuItem>
            <MenuItem value="Asian">Asian</MenuItem>
            <MenuItem value="Indian">Indian</MenuItem>
            <MenuItem value="Not disclosed">Not disclosed</MenuItem>
          </Select>

          <Box display="flex" justifyContent={'flex-end'}>
            <PrimaryButton onClick={handleEdit}>{!editable ? 'Edit' : 'Save'}</PrimaryButton>
          </Box>
        </Form>
      </Grid>
    </Grid>
  );
}

export default Profile;
