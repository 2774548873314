import axios from 'axios';
import { authHeader } from '../helpers';

const apiClient = axios.create({
  baseURL: '/api/educationalVideos',
  header: {
    'Content-Type': 'application/json'
  }
});

const getAll = async () => {
  return apiClient.get(`/`, { headers: { ...authHeader() } }).then((response) => {
    return response.data;
  });
};

export const educationalVideosService = {
  getAll
};
