exports.calculateProfileProgress = (profile, totalCourses) => {
  let points = 0;
  let totalPercentage = 0.0;

  if (profile.email) {
    points++;
  }

  if (profile.firstName) {
    points++;
  }

  if (profile.lastName) {
    points++;
  }

  if (profile.phoneNumber) {
    points++;
  }

  if (profile.race) {
    points++;
  }

  if (profile.gender) {
    points++;
  }

  if (totalCourses && totalCourses > 0) {
    points++;
  }

  totalPercentage = (points / 7) * 100;
  return totalPercentage;
};
