import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Alert, Divider, Grid, Link, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { getCourseModuleById } from '../../../features/courseModule/courseModule.slice';
import YouTubePlayer from '../../ui/youTubePlayer';
import PDFViewerDialog from '../../ui/pdfViewerDialog';
import PrimaryButton from '../../ui/buttons/primaryButton';
import VideoPlayer from '../../ui/videoPlayer';
import { updateCourseSection } from '../../../features/course/course.slice';
import { useTitle } from '../../../hoc/titleProvider';
import DynamicForm from '../../forms/dynamicForm';
import useMediaQuery from '@mui/material/useMediaQuery';

function CourseModule() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setTitle } = useTitle();

  const courseModule = useSelector((state) => state.courseModule.selectedModule);
  const course = useSelector((state) => state.course.selectedCourse);
  const courseProgress = useSelector((state) => state.course.progress);
  const [player, setPlayer] = useState(false);
  const [pdfOpen, setPdfOpen] = useState({});

  const [videoWatched, setVideoWatched] = useState(false);
  const [videoProgress, setVideoProgress] = useState({});

  const theme = useTheme();

  useEffect(() => {
    setTitle(`${course.name} - ${courseModule.name}`);
    window.scrollTo(0, 0);
  }, [courseModule, course]);

  const handlePlayer = () => {
    setPlayer(true);
  };

  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClosePlayer = (sectionId) => {
    if (videoWatched) {
      //Update with csection complete and other.
      dispatch(
        updateCourseSection({
          sectionId,
          update: { progress: videoProgress, complete: true }
        })
      );
    } else {
      //Update with not complete and stats.
      if (!isComplete(sectionId))
        dispatch(
          updateCourseSection({
            sectionId,
            update: { progress: videoProgress, complete: false }
          })
        );
    }

    setPlayer(false);
  };

  const handlePdfOpen = (sectionId) => {
    dispatch(
      updateCourseSection({
        sectionId,
        update: { complete: true }
      })
    );

    setPdfOpen({ [sectionId]: true });
  };

  const handlePdfClose = (sectionId) => {
    setPdfOpen({ [sectionId]: false });
  };

  const handleVideoProgress = (event) => {
    setVideoProgress(event);
    if (event.percent >= 98) {
      setVideoWatched(true);
    }
  };

  const handleFormSubmit = (score, sectionId) => {
    if (score) {
      dispatch(
        updateCourseSection({
          sectionId,
          update: { complete: score.pass, score }
        })
      );
    } else {
      dispatch(
        updateCourseSection({
          sectionId,
          update: { complete: true }
        })
      );
    }
  };

  const handleExternalLink = (sectionId) => {
    dispatch(
      updateCourseSection({
        sectionId,
        update: { complete: true }
      })
    );
  };

  const handleBack = () => {
    navigate(-1);
  };

  const isComplete = (sctionId) => {
    if (courseProgress && courseProgress.modules.length > 0) {
      const moduleIndex = courseProgress.modules.findIndex(
        (m) => m.module.toString() === courseModule._id
      );

      if (courseProgress.modules[moduleIndex]) {
        const sectionIndex = courseProgress.modules[moduleIndex].sections.findIndex(
          (s) => s._id.toString() === sctionId
        );

        const stats = courseProgress.modules[moduleIndex].sections[sectionIndex];

        return stats;
      }

      return false;
    }
  };

  return (
    courseModule && (
      <Box>
        <Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row-reverse',
              p: 1,
              m: 1
            }}
          >
            <PrimaryButton onClick={handleBack} variant="outlined">
              Back
            </PrimaryButton>
          </Box>
          {/* <Box alignContent={"right"}>

          </Box> */}
          <div>
            <Typography variant={smallScreen ? 'h3' : 'h1'}>{courseModule.name}</Typography>
            <Typography variant="body1">{courseModule.description}</Typography>
          </div>
        </Box>
        <Divider sx={{ m: 3 }} />
        {courseModule &&
          courseModule.sections.map((section) => {
            const completeStats = isComplete(section._id);
            switch (section.sectionType) {
              case 'video':
                return (
                  <Box>
                    <Grid container key={section._id} spacing={2} alignItems={'center'}>
                      <Grid item xs={12} md={8}>
                        <Typography variant="h4" color="primary">
                          {section.heading || 'Watch Video'}
                        </Typography>
                        <Typography>{section.description}</Typography>

                        {section.transcript && (
                          <Typography>
                            Video transcript available{' '}
                            <Link
                              onClick={() => handlePdfOpen(section._id)}
                              sx={{ cursor: 'pointer' }}
                            >
                              here
                            </Link>
                            , should reading be your preferred learning style.
                          </Typography>
                        )}
                        <Typography>
                          <PrimaryButton onClick={() => handlePlayer(section._id)}>
                            View
                          </PrimaryButton>
                          <VideoPlayer
                            open={player}
                            onClose={() => handleClosePlayer(section._id)}
                            title={courseModule.name}
                            videoId={section.link}
                            downloadLink={section.downloadLink || null}
                            onProgress={(event) => handleVideoProgress(event)}
                          />
                          {section.transcript && (
                            <PDFViewerDialog
                              open={pdfOpen[section._id]}
                              onClose={() => handlePdfClose(section._id)}
                              pdf={section.transcript}
                              section={section}
                              title={'Video Transcript'}
                            />
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography>
                          {completeStats && completeStats.complete && (
                            <Alert severity="success" sx={{ width: 250 }}>
                              Completed
                            </Alert>
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider sx={{ m: 3 }} />
                  </Box>
                );
              case 'document':
                return (
                  <Box key={section._id}>
                    <Grid container spacing={2} alignItems={'center'}>
                      <Grid item xs={12} md={8}>
                        <Typography variant="h4" color="primary">
                          {section.heading || 'Written material'}
                        </Typography>
                        <Typography>{section.description}</Typography>

                        <Typography>
                          <PrimaryButton onClick={() => handlePdfOpen(section._id)}>
                            View
                          </PrimaryButton>
                          <PDFViewerDialog
                            open={pdfOpen[section._id]}
                            onClose={() => handlePdfClose(section._id)}
                            pdf={section.link}
                            section={section}
                            title={section.heading || 'Written material'}
                          />
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography>
                          {completeStats && completeStats.complete && (
                            <Alert severity="success" sx={{ width: 250 }}>
                              Completed
                            </Alert>
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider sx={{ m: 3 }} />
                  </Box>
                );
              case 'download':
                return (
                  <Box key={section._id}>
                    <Grid container spacing={2} alignItems={'center'}>
                      <Grid item xs={12} md={8}>
                        <Typography variant="h4" color="primary">
                          {section.heading || 'Written material'}
                        </Typography>
                        <Typography>{section.description}</Typography>

                        <Typography>
                          <PrimaryButton
                            href={section.link}
                            target={'_blank'}
                            onClick={() => handlePdfOpen(section._id)}
                          >
                            Download
                          </PrimaryButton>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography>
                          {completeStats && completeStats.complete && (
                            <Alert severity="success" sx={{ width: 250 }}>
                              Completed
                            </Alert>
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider sx={{ m: 3 }} />
                  </Box>
                );
              case 'form':
                return (
                  <Box key={section._id}>
                    <Grid container spacing={2} alignItems={'center'}>
                      <Grid item xs={12} md={8}>
                        <Typography variant="h4" color="primary">
                          {section.heading || 'Form'}
                        </Typography>
                        <Typography>{section.description}</Typography>
                        <DynamicForm
                          formId={section.form}
                          onFormSubmit={(score) => handleFormSubmit(score, section._id)}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography>
                          {completeStats && completeStats.complete && completeStats.score && (
                            <Alert severity="success" sx={{ width: 250 }}>
                              Passed - {completeStats.score.percentage}%
                            </Alert>
                          )}

                          {completeStats && !completeStats.complete && completeStats.score && (
                            <Alert severity="warning" sx={{ width: 250 }}>
                              Not Passing - {completeStats.score.percentage}%
                            </Alert>
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider sx={{ m: 3 }} />
                  </Box>
                );
              case 'externalLink':
                return (
                  <Box key={section._id}>
                    <Grid container spacing={2} alignItems={'center'}>
                      <Grid item xs={12} md={8}>
                        <Typography variant="h4" color="primary">
                          {section.heading || 'Quiz'}
                        </Typography>
                        <Typography>{section.description}</Typography>
                        <PrimaryButton
                          href={section.link}
                          target="_blank"
                          onClick={() => handleExternalLink(section._id)}
                        >
                          Start
                        </PrimaryButton>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography>
                          {completeStats && completeStats.complete && (
                            <Alert severity="success" sx={{ width: 250 }}>
                              Completed
                            </Alert>
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider sx={{ m: 3 }} />
                  </Box>
                );
              default:
                break;
            }
          })}
        {courseModule.teaser ? (
          <>
            <Typography variant={smallScreen ? 'h4' : 'h2'} sx={{ fontWeight: 400 }}>
              {'What’s next?'}
            </Typography>
            <Typography>{courseModule.teaser}</Typography>
          </>
        ) : (
          course.altText && <Typography>{course.altText}</Typography>
        )}
      </Box>
    )
  );
}

export default CourseModule;
