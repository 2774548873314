import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import logger from 'redux-logger';

import authReducer from '../features/auth/auth.slice';
import alertReducer from '../features/alert/alert.slice';
import courseReducer from '../features/course/course.slice';
import courseModuleReducer from '../features/courseModule/courseModule.slice';
import formReducer from '../features/form/form.slice';
import educationalVideosReducer from '../features/educationalVideos/educationalVideos.slice';
import userReducer from '../features/user/user.slice';

const rootReducser = combineReducers({
  auth: authReducer,
  user: userReducer,
  alert: alertReducer,
  course: courseReducer,
  courseModule: courseModuleReducer,
  form: formReducer,
  educationalVideos: educationalVideosReducer
});

export const store = configureStore({
  reducer: rootReducser,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
  devTools: process.env.NODE_ENV !== 'production'
});
