import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PrimaryButton from '../../ui/buttons/primaryButton';
import Form from '../../ui/forms';
import Input from '../../ui/forms/fields/input';

import { useDispatch, useSelector } from 'react-redux';
import { updatePassword } from '../../../features/auth/auth.slice';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import OrangeCircularProgress from '../../ui/controls/circularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 0, 2, 5),
    maxWidth: '250px',
    height: '50%'
  }
}));

const schema = yup.object().shape({
  password: yup
    .string()
    .min(6, 'Password must be at least 6 characters')
    .required('Password is required.'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
  token: yup.string().required('A reset token is required for this request.')
});

function UpdatePasswordForm({ token, ...props }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  });

  const loading = useSelector((state) => state.auth.loading);
  const success = useSelector((state) => state.auth.success);

  const onSubmit = (data) => {
    dispatch(updatePassword(data));
  };

  return (
    <Box className={classes.root} display="flex" flexDirection="column" justifyContent="center">
      <Typography variant="h5">Update Password</Typography>

      {loading ? (
        <OrangeCircularProgress />
      ) : !success ? (
        <div className={classes.paper}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Input
              label="Password"
              type="password"
              autoComplete="current-password"
              error={!!errors.password}
              helperText={errors?.password?.message}
              {...register('password')}
            />
            <Input
              label="Confirm Password"
              type="password"
              autoComplete="current-password"
              error={!!errors.confirmPassword}
              helperText={errors?.confirmPassword?.message}
              {...register('confirmPassword')}
            />
            <input type="hidden" value={token} {...register('token')} />
            {!!errors.token ? (
              <Typography color="error">{errors?.token?.message}</Typography>
            ) : null}

            <PrimaryButton type={'submit'}>Update Password</PrimaryButton>
          </Form>
        </div>
      ) : (
        <div>
          <Typography>Your password was updated.</Typography>
          <Link href="/signin" style={{ cursor: 'pointer' }}>
            Back to sign in
          </Link>
        </div>
      )}
    </Box>
  );
}

export default UpdatePasswordForm;
