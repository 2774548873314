import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, CardActions } from '@mui/material';

import ManWithIpad from '../../../resources/images/ManWithIpad.jpg';
import { Box } from '@mui/system';

function CourseTile(props) {
  const handleClick = () => {
    //Navigate to modules
    props.onClick();
  };

  return (
    <Card
      sx={{
        minWidth: 320,
        maxWidth: 320,
        m: 2,
        display: 'inline-block'
      }}
    >
      <CardActionArea disabled={!props.course.active} onClick={handleClick} sx={{}}>
        <CardMedia
          component="img"
          height="180"
          image={props.course.banner}
          sx={{ objectFit: 'contain' }}
        />
        {!props.course.active && (
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              top: 0,
              width: '100%',
              bgcolor: 'rgba(0, 0, 0, 0.54)'
            }}
          ></Box>
        )}
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {props.course.name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {props.course.description}
          </Typography>
        </CardContent>
        <CardActions>
          {props.course.active ? (
            props.course.altText ? (
              <Typography color="primary">{props.course.altText}</Typography>
            ) : (
              <Typography color="primary">Available</Typography>
            )
          ) : (
            <Typography color="primary">Coming soon</Typography>
          )}
        </CardActions>
      </CardActionArea>
    </Card>
  );
}

export default CourseTile;
