import React, { useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, FormControl, FormHelperText, FormGroup } from '@mui/material';
import { Controller, useController } from 'react-hook-form';

const CheckboxList = ({ children, control, name, label, options, rules, ...props }) => {
  const {
    field: { onChange, value }
  } = useController({
    name,
    control,
    rules,
    defaultValue: props.value || ''
  });

  const [items, setItems] = useState(value);

  useEffect(() => {
    onChange(items);
  }, [items]);

  const handleCheckboxChange = (event) => {
    const id = event.target.id;
    setItems((currentParticipants) =>
      currentParticipants.includes(id)
        ? currentParticipants.filter((f) => f !== id)
        : [...currentParticipants, id]
    );
  };

  return (
    <FormControl error={props.error}>
      <FormGroup onChange={handleCheckboxChange}>
        {options.map((item, index) => (
          <FormControlLabel key={index} control={<Checkbox id={item.value} />} label={item.text} />
        ))}
      </FormGroup>
      <FormHelperText>{props.helperText}</FormHelperText>
    </FormControl>
  );
};

export default CheckboxList;
