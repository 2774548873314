import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Link, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PrimaryButton from '../../ui/buttons/primaryButton';
import Form from '../../ui/forms';
import Input from '../../ui/forms/fields/input';

import { useDispatch, useSelector } from 'react-redux';
import { forgotPassword } from '../../../features/auth/auth.slice';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import OrangeCircularProgress from '../../ui/controls/circularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 0, 2, 5),
    maxWidth: '250px',
    height: '50%'
  }
}));

const schema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter your email address')
    .required('Your email address is required.')
});

function ForgotPasswordForm(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  });

  const loading = useSelector((state) => state.auth.loading);
  const success = useSelector((state) => state.auth.success);

  const onSubmit = (data) => {
    dispatch(forgotPassword(data));
  };

  return (
    <Box className={classes.root} display="flex" flexDirection="column" justifyContent="center">
      <Typography variant="h5">Recover Password</Typography>

      {loading ? (
        <OrangeCircularProgress />
      ) : !success ? (
        <Box className={classes.paper}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Input
              label="Email Address"
              autoComplete="email"
              autoFocus
              error={!!errors.email}
              helperText={errors?.email?.message}
              {...register('email')}
            />

            <PrimaryButton type={'submit'}>Recover Password</PrimaryButton>

            <Grid container>
              <Grid item>
                <Link href="/signin" variant="body2">
                  {'Remembered your password? Sign in here'}
                </Link>
              </Grid>
            </Grid>
          </Form>
        </Box>
      ) : (
        <Box>
          <Typography>
            Please check your inbox for a recovery email. Please make sure to check your SPAM box as
            well.
          </Typography>
          <Link href="/signin" style={{ cursor: 'pointer' }}>
            Back to sign in
          </Link>
        </Box>
      )}
    </Box>
  );
}

export default ForgotPasswordForm;
