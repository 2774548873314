import axios from 'axios';
import { authHeader } from '../helpers';

const apiUser = axios.create({
  baseURL: '/api/user',
  header: {
    'Content-Type': 'application/json'
  }
});

const apiCourseUser = axios.create({
  baseURL: '/api/courseuser',
  header: {
    'Content-Type': 'application/json'
  }
});

const getLoggedInUser = async () => {
  return apiUser.get(`/loggedin`, { headers: { ...authHeader() } }).then((response) => {
    return response.data;
  });
};

const getCoursesProgress = async (userId) => {
  return apiCourseUser
    .get(`/enrolled/${userId}`, { headers: { ...authHeader() } })
    .then((response) => {
      return response.data;
    });
};

const update = async (data) => {
  return apiUser.put(`/${data.id}`, data, { headers: { ...authHeader() } }).then((response) => {
    return response.data;
  });
};

export const userService = {
  getLoggedInUser,
  getCoursesProgress,
  update
};
