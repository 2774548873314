import axios from 'axios';
import { authHeader } from '../helpers';

const apiClient = axios.create({
  baseURL: '/api/course',
  header: {
    'Content-Type': 'application/json'
  }
});

const getAll = async () => {
  return apiClient.get(`/`, { headers: { ...authHeader() } }).then((response) => {
    return response.data;
  });
};

const getById = async (id) => {
  return apiClient.get(`/${id}`, { headers: { ...authHeader() } }).then((response) => {
    return response.data;
  });
};

const enroll = async (courseId) => {
  return apiClient.get(`/${courseId}/enroll`, { headers: { ...authHeader() } }).then((response) => {
    return response.data;
  });
};

const updateSection = async (courseId, moduleId, sectionId, data) => {
  const body = {
    moduleId,
    sectionId,
    update: data
  };

  return apiClient
    .put(`/${courseId}/section`, body, { headers: { ...authHeader() } })
    .then((response) => {
      return response.data;
    });
};

const getProgress = async (courseId) => {
  return apiClient
    .get(`/${courseId}/progress`, { headers: { ...authHeader() } })
    .then((response) => {
      return response.data;
    });
};

const getRequestCertificate = async (courseId) => {
  return apiClient
    .get(`/${courseId}/requestcertificate`, { headers: { ...authHeader() } })
    .then((response) => {
      return response.data;
    });
};

export const courseService = {
  getAll,
  getById,
  enroll,
  updateSection,
  getProgress,
  getRequestCertificate
};
