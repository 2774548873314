import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/system';
import {
  getAllPlayLists,
  setSelectedVideo
} from '../../features/educationalVideos/educationalVideos.slice';
import VideoTile from './videoTile';
import { useTitle } from '../../hoc/titleProvider';

function PlaylistVidoes() {
  const dispatch = useDispatch();
  const videos = useSelector((state) => state.educationalVideos.selectedPlaylist.videos);

  const [player, setPlayer] = useState(false);

  const { setTitle } = useTitle();
  setTitle('Investment Education Short Videos');

  useEffect(() => {
    dispatch(getAllPlayLists());
  }, []);

  const handlePlayer = (video) => {
    dispatch(setSelectedVideo(video));
    setPlayer(true);
  };
  const handleClosePlayer = (sectionId) => {
    setPlayer(false);
  };

  return (
    <Box>
      {videos &&
        videos.map((playlist) => (
          <>
            <VideoTile key={playlist} video={playlist} onClick={() => handlePlayer(playlist)} />
          </>
        ))}
    </Box>
  );
}

export default PlaylistVidoes;
