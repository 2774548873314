import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ProfileNavigation from './profileNavigation';
import BottomNavigation from '@mui/material/BottomNavigation';
import Paper from '@mui/material/Paper';
import { Box, Link, useMediaQuery } from '@mui/material';
import PrimaryButton from './buttons/primaryButton';
import SmallLogoIcon from './smallLogoIcon';
import { useTitle } from '../../hoc/titleProvider';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    //marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerHeader: {
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  spacer: {
    visibility: 'hidden'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: -drawerWidth,
    marginBottom: '72px'
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  title: {
    flexGrow: 1
  },
  listLink: {
    color: theme.palette.text.primary
  },
  listWrap: {
    //color: theme.palette.secondary,
    textAlign: 'center',
    '&:focus': {
      background: theme.palette.primary.main,
      color: 'white'
    }
  },
  listItemText: {},
  listItemActive: {
    background: theme.palette.primary.main,
    color: 'white'
  },
  btnAdmin: {
    borderRadius: 0,
    position: 'absolute',
    bottom: 0
  }
}));

const PrimaryNavigation = ({ children, ...props }) => {
  const classes = useStyles();
  const { title } = useTitle();

  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'), {
    noSsr: true
  });

  const [open, setOpen] = useState(!isMobile);
  const user = useSelector((state) => state.auth.user);

  const [navItems, setNavItems] = useState([
    {
      text: 'My Financial Education',
      secoundaryText: '',
      link: '/',
      soon: false
    },
    {
      text: 'Courses',
      secoundaryText: '',
      link: '/courses',
      soon: false
    },
    {
      text: 'Short Videos',
      secoundaryText: '',
      link: '/short-videos',
      soon: false
    },
    {
      text: 'Contact Us',
      secoundaryText: '',
      link: '/contact',
      soon: false
    }
  ]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const hanldeItemClick = () => {
    if (isMobile) {
      setOpen(false);
    }
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        color={'background'}
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}
      >
        <Toolbar>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
              size="large"
            >
              <MenuIcon />
            </IconButton>
            {/* <div className={classes.title}><Search/></div> */}

            <Typography variant="h4" noWrap className={classes.title}>
              {title}
            </Typography>
            <ProfileNavigation />
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <Box
          className={classes.drawerHeader}
          display="flex"
          flexDirection="row"
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <div className={classes.spacer}>Spacer</div>
          <Box display="flex" alignSelf="center">
            <SmallLogoIcon />
          </Box>
          <IconButton onClick={handleDrawerClose} size="large">
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </Box>
        <Divider />
        <List>
          {navItems.map((item, index) =>
            !item.soon ? (
              <ListItem
                button
                className={clsx(classes.listWrap, ({ isActive }) => classes.listItemActive)}
                onClick={hanldeItemClick}
                component={NavLink}
                to={item.link}
                key={item.text}
              >
                <ListItemText className={classes.listItemText} primary={item.text} />
              </ListItem>
            ) : null
          )}
        </List>
        <Divider />

        {/* <Typography
          style={{
            textAlign: "center",
            marginRight: "5px",
            paddingTop: "10px",
          }}
          variant="span"
          color="primary"
        >
          Coming Soon
        </Typography>
        <List>
          {navItems.map((item, index) =>
            item.soon ? (
              <ListItem
                button
                disabled
                key={item.text}
                className={classes.listWrap}
              >
                <ListItemText primary={item.text} />
              </ListItem>
            ) : null
          )}
        </List> */}
      </Drawer>

      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open
        })}
      >
        <div className={classes.drawerHeader} />
        <Box
          mb={'-90px'}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          minHeight={'calc(100vh - 90px)'}
        >
          <div>{children}</div>
          <Box
            px={5}
            py={2}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            textAlign="center"
          >
            <Typography variant="body1" sx={{ fontSize: '8px' }}>
              Fairtree © 2021. All rights reserved.{' '}
              <strong>
                <Link
                  href="https://fairtree.com/disclaimer/"
                  underline="hover"
                  rel="noopener"
                  target="_blank"
                >
                  {'Disclaimer '}
                </Link>
                |
                <Link
                  href="https://fairtree.com/wp-content/uploads/2022/03/Fairtree-PAIA-Manual-202201.pdf"
                  underline="hover"
                  rel="noopener"
                  target="_blank"
                >
                  {' PAIA Manual'}
                </Link>{' '}
                |
                <Link
                  href="https://fairtree.com/wp-content/uploads/2022/03/Fairtree-Privacy-Notice-202201.pdf"
                  underline="hover"
                  rel="noopener"
                  target="_blank"
                >
                  {' Privacy Notice'}
                </Link>{' '}
              </strong>
            </Typography>
            <Typography variant="body1" sx={{ fontSize: '8px' }}>
              The Fairtree group consists of the following regulated entities: 1) Fairtree Asset
              Management (Pty) Ltd (South Africa, license no. 25917); 2) Fairtree Capital
              Hospitality (Pty) Ltd (South Africa, license no. 46033); 3) Fairtree Asset Management
              LLP (United Kingdom, license no. 826211); 4) Fairtree Capital International IC Ltd
              (Guernsey, license no. 2293281); 5) Fairtree Private Client (Pty) Ltd is an Authorised
              Financial Services Provider. (FSP 12179)
            </Typography>
          </Box>
        </Box>
      </main>
    </div>
  );
};

export default PrimaryNavigation;
