import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Layout from './layout';

const PublicRoute = (props) =>
  localStorage.getItem('user') ? (
    props.redirectAdmin === false ? (
      <Layout>
        <Outlet />
      </Layout>
    ) : (
      <Navigate to={'/signin'} />
    )
  ) : (
    <Layout>
      <Outlet />
    </Layout>
  );

export default PublicRoute;
