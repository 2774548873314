import React, { useEffect, useState, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography
} from '@mui/material';
import { useTitle } from '../../hoc/titleProvider';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import PrimaryButton from '../ui/buttons/primaryButton';
import { Box } from '@mui/system';
import { getCourseProgress, getLoggedInUser } from '../../features/user/user.slice';
import LinearProgressWithLabel from '../ui/controls/linearProgressWithLabel';
import { calculateProfileProgress } from '../../utils/userHelpers';
import { getRequestCertificate } from '../../features/course/course.slice';

function Home() {
  const { setTitle } = useTitle();
  setTitle('My Financial Education');

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const progress = useSelector((state) => state.user.courseProgress);
  const [profileProgress, setProfileProgress] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getLoggedInUser());
  }, []);

  useEffect(() => {
    if (user && user._id) {
      dispatch(getCourseProgress(user._id));
    }
  }, [user]);

  useEffect(() => {
    if (progress && user._id) setProfileProgress(calculateProfileProgress(user, progress.length));
  }, [progress, user]);

  const handleCertificateRequest = (id) => {
    dispatch(getRequestCertificate(id));
  };
  return (
    <Grid container>
      <Grid item md={6} xs={12}>
        <Paper elevation={2} sx={{ p: 2, m: 2 }}>
          <Typography variant="h3" sx={{ mb: 2 }}>
            My Courses
          </Typography>
          <Box>
            {progress &&
              progress.map((item, index) => (
                <Grid container>
                  <Grid item md={8}>
                    <Box display="flex" flexDirection="column" justifyContent="center">
                      <ListItemText primary={item.name} />
                      <LinearProgressWithLabel
                        value={(item.completedModules / item.totalModules) * 100}
                      />
                    </Box>
                  </Grid>
                  {item.completedModules === item.totalModules ? (
                    <Grid item md={4}>
                      <Box>
                        <PrimaryButton
                          variant={'outlined'}
                          size="small"
                          onClick={() => handleCertificateRequest(item.id)}
                        >
                          Request Certificate
                        </PrimaryButton>
                      </Box>
                    </Grid>
                  ) : null}
                </Grid>
              ))}
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <PrimaryButton onClick={() => navigate('/courses')}>Start new course</PrimaryButton>
          </Box>
        </Paper>
      </Grid>
      <Grid item md={6} xs={12}>
        <Paper elevation={2} sx={{ p: 2, m: 2 }}>
          <Typography variant="h3" sx={{ mb: 2 }}>
            Profile Progress
          </Typography>
          <Box>
            <>
              <LinearProgressWithLabel value={profileProgress} />
              {profileProgress && profileProgress === 100 ? (
                <Typography>Well done, your profile is complete!</Typography>
              ) : (
                ''
              )}
              <List>
                {profileProgress !== 100 && (
                  <ListItem>
                    <ListItemIcon>
                      <ArrowCircleRightIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText primary="Complete your Profile." />
                  </ListItem>
                )}
              </List>
            </>
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <PrimaryButton onClick={() => navigate('/user/profile')}>Edit Profile</PrimaryButton>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default Home;
