import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, CardActions } from '@mui/material';

import { Box } from '@mui/system';

function PlayListTitle(props) {
  const handleClick = () => {
    //Navigate to modules
    props.onClick();
  };

  return (
    <Card
      sx={{
        m: 2,
        display: 'inline-block',
        minWidth: 320,
        maxWidth: 320
      }}
    >
      <CardActionArea onClick={handleClick} sx={{}}>
        <CardMedia
          component="img"
          height="180"
          image={props.playlist.banner}
          sx={{ objectFit: 'contain' }}
        />
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            top: 0,
            width: '100%'
          }}
        ></Box>

        <CardContent>
          <Typography gutterBottom variant="h6" component="div">
            Click here to access the video playlist
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default PlayListTitle;
