import React from 'react';
import { FormControl, FormControlLabel, FormHelperText, Slider, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import { Box } from '@mui/system';

const ScaleSlider = ({ control, name, label, max, startText, endText, ...props }) => {
  let marks = [];
  for (let i = 0; i < max; i++) {
    marks.push({
      value: i,
      label: i + 1
    });
  }

  function valueLabelFormat(value) {
    return marks.findIndex((mark) => mark.value === value) + 1;
  }

  function valuetext(value) {
    return `${value}`;
  }

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={props.value || false}
      rules={props.rules}
      render={({ field: { onChange, value } }) => (
        <FormControl fullWidth {...props}>
          <Box
            display={{ xs: 'inline', md: 'flex' }}
            flexDirection={'row'}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4" sx={{ m: 2 }}>
              {startText}
            </Typography>
            <Slider
              aria-label={label}
              valueLabelFormat={valueLabelFormat}
              getAriaValueText={valuetext}
              step={null}
              valueLabelDisplay="auto"
              marks={marks}
              onChange={onChange}
              max={max - 1}
              defaultValue={props.value}
            />
            <Typography variant="h4" sx={{ m: 2 }}>
              {endText}
            </Typography>
          </Box>
          {props.helperText && <FormHelperText>{props.helperText}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};

export default ScaleSlider;
