import { Dialog, DialogActions, DialogTitle } from '@mui/material';
import React from 'react';
import YouTube from 'react-youtube';
import PrimaryButton from './buttons/primaryButton';

function YouTubePlayer(props) {
  const options = {
    height: '720',
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      enablejsapi: 1,
      origin: '*',
      color: 'white',
      autoplay: 1,
      modestbranding: 1,
      rel: 0
    }
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="md">
      <DialogTitle>{props.title}</DialogTitle>
      <YouTube videoId={props.videoId} opts={options} onEnd={props.onEnd} />
      <DialogActions>
        <PrimaryButton onClick={props.onClose}>Close</PrimaryButton>
      </DialogActions>
    </Dialog>
  );
}

export default YouTubePlayer;
