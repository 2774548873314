import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Link, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PrimaryButton from '../../ui/buttons/primaryButton';
import Form from '../../ui/forms';
import Input from '../../ui/forms/fields/input';

import { useDispatch } from 'react-redux';
import { login, logout } from '../../../features/auth/auth.slice';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 0, 2, 4),
    maxWidth: '250px',
    height: '50%'
  }
}));

const schema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required('Email is required.')
    .email('This does not look like a email address.'),
  password: yup.string().required('Password is required.')
});

function SignInForm() {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema)
  });
  const classes = useStyles();

  useEffect(() => {
    dispatch(logout());
  }, [dispatch]);

  const onSubmit = (data) => {
    dispatch(login(data));
  };

  return (
    <Box className={classes.root} display="flex" flexDirection="column" justifyContent="center">
      <Typography variant="h5">Login</Typography>
      <Typography variant="body2">“Sign up” or “Login” to access this free platform.</Typography>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input
          label="Email Address"
          autoComplete="email"
          autoFocus
          error={!!errors.email}
          helperText={errors?.email?.message}
          {...register('email')}
        />
        <Input
          label="Password"
          type="password"
          autoComplete="current-password"
          error={!!errors.password}
          helperText={errors?.password?.message}
          {...register('password')}
        />

        <PrimaryButton type="submit">Sign In</PrimaryButton>

        <Grid container direction="column">
          <Grid item xs>
            <Link href="/forgot-password" variant="body2">
              Forgot password?
            </Link>
          </Grid>
          <Grid item>
            <Link href="/signup" variant="body2">
              {"Don't have an account? Sign Up"}
            </Link>
          </Grid>
        </Grid>
      </Form>
    </Box>
  );
}

export default SignInForm;
