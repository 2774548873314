import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    margin: theme.spacing(2)
  },
  circle: {
    strokeLinecap: 'round'
  }
}));

const OrangeCircularProgress = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress
        {...props}
        color="primary"
        variant="indeterminate"
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle
        }}
        size={40}
        thickness={4}
      />

      {props.caption ? <Typography component="p">{props.caption}</Typography> : null}
    </div>
  );
};

export default OrangeCircularProgress;
