import axios from 'axios';
import { authHeader } from '../helpers';

const apiClient = axios.create({
  baseURL: '/api/user',
  header: {
    'Content-Type': 'application/json'
  }
});

const auth = async () => {
  return apiClient.get('/', { headers: { ...authHeader() } }).then((response) => {
    let user = JSON.parse(localStorage.getItem('user'));
    user = { ...user, ...response.data.user };

    localStorage.setItem('user', JSON.stringify(user));
    return user;
  });
};

const login = async (credentials) => {
  return apiClient.post(`/login`, credentials).then((response) => {
    if (response.data.token) {
      localStorage.setItem('user', JSON.stringify(response.data));
    }
    return response.data;
  });
};

const register = async (credentials) => {
  return apiClient.post(`/register`, credentials).then((response) => {
    if (response.data.token) {
      localStorage.setItem('user', JSON.stringify(response.data));
    }
    return response.data;
  });
};

const sendEmailVerification = async () => {
  return apiClient.get(`/email/verification/send`).then((response) => {
    return response.data;
  });
};

const verifyEmailAddress = async (token) => {
  return apiClient.get(`/email/verification/confirm/${token}`).then((response) => {
    if (response.data.success) {
      // const user = JSON.parse(localStorage.getItem("user"));
      // user.emailConfirmed = response.data.emailConfirmed;
      // user.emailToken = response.data.emailToken;
      // localStorage.setItem("user", JSON.stringify(user));
    }

    return response.data;
  });
};

const forgotPassword = async (credentials) => {
  return apiClient.post(`/forgot-password`, credentials).then((response) => {
    return response.data;
  });
};

const updatePassword = async (credentials) => {
  return apiClient.post(`/update-password`, credentials).then((response) => {
    return response.data;
  });
};

const logout = async () => {
  localStorage.removeItem('user');
  return apiClient.get(`/logout`);
};

export const authService = {
  auth,
  login,
  logout,
  register,
  sendEmailVerification,
  verifyEmailAddress,
  forgotPassword,
  updatePassword
};
