import { Dialog, DialogActions, DialogContent, DialogTitle, Link } from '@mui/material';
import React from 'react';
import ReactPlayer from 'react-player';
import PrimaryButton from './buttons/primaryButton';

function VideoPlayer(props) {
  //https://www.npmjs.com/package/react-player

  const handleOnProgress = (event) => {
    props.onProgress({
      percent: event.played * 100,
      secounds: event.playedSeconds
    });
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="md">
      <DialogTitle>{props.title}</DialogTitle>
      <ReactPlayer
        url={props.videoId}
        onEnded={props.onEnd}
        onProgress={handleOnProgress}
        playing={true}
        loop={true}
        config={{
          youtube: {
            playerVars: {
              color: 'white',
              modestbranding: 1,
              controls: 1
            }
          }
        }}
        width={'100%'}
      />
      <DialogContent></DialogContent>
      <DialogActions>
        {props.downloadLink && (
          <Link
            href={props.downloadLink}
            sx={{ cursor: 'pointer' }}
            download={true}
            target="_blank"
          >
            Download Video
          </Link>
        )}
        <PrimaryButton onClick={props.onClose}>Close</PrimaryButton>
      </DialogActions>
    </Dialog>
  );
}

export default VideoPlayer;
