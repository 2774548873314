import React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Controller } from 'react-hook-form';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { TextField } from '@mui/material';

function GreatDatePicker({ name, label, control, defaultValue, ...props }) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label={label}
            inputFormat={props.inputFormat || 'DD/MM/YYYY'}
            onChange={onChange}
            value={value}
            renderInput={(params) => (
              <TextField id={name} margin="dense" size={'small'} fullWidth {...params} />
            )}
            {...props}
          />
        </LocalizationProvider>
      )}
    />
  );
}

export default GreatDatePicker;
