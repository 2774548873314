import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setAlertSuccess, setAlertError } from '../alert/alert.slice';
import { userService } from './user.service';

export const getLoggedInUser = createAsyncThunk('user/getLoggedInUser', async (data, thunkAPI) => {
  try {
    const response = await userService.getLoggedInUser();
    return response;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    thunkAPI.dispatch(setAlertError(message));
    return thunkAPI.rejectWithValue();
  }
});

export const getCourseProgress = createAsyncThunk(
  'user/getCourseProgress',
  async (data, thunkAPI) => {
    try {
      const response = await userService.getCoursesProgress(data);
      return response;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();

      thunkAPI.dispatch(setAlertError(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateUser = createAsyncThunk('user/updateUser', async (data, thunkAPI) => {
  try {
    const response = await userService.update(data);

    if (response.success) {
      thunkAPI.dispatch(setAlertSuccess('Successfully updated your profile.'));
    }

    return response;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    thunkAPI.dispatch(setAlertError(message));
    return thunkAPI.rejectWithValue();
  }
});

const initialState = {
  loading: false
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  extraReducers: {
    [getCourseProgress.pending]: (state) => {
      state.loading = true;
    },
    [getCourseProgress.fulfilled]: (state, action) => {
      state.loading = false;
      state.courseProgress = action.payload.courses;
    },
    [getCourseProgress.rejected]: (state) => {
      state.loading = false;
    },
    [getLoggedInUser.pending]: (state) => {
      state.loading = true;
    },
    [getLoggedInUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.user = action.payload.user;
    },
    [getLoggedInUser.rejected]: (state) => {
      state.loading = false;
    },
    [updateUser.pending]: (state) => {
      state.loading = true;
    },
    [updateUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.user = action.payload.user;
    },
    [updateUser.rejected]: (state) => {
      state.loading = false;
    }
  }
});

export default userSlice.reducer;
