import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setAlertError } from '../alert/alert.slice';
import { courseModuleService } from './courseModule.service';

export const getByCourse = createAsyncThunk(
  'courseModule/getAllCourses',
  async (courseId, thunkAPI) => {
    try {
      const response = await courseModuleService.getByCourse(courseId);
      return { courseModules: response };
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setAlertError(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getCourseModuleById = createAsyncThunk(
  'courseModule/getById',
  async (id, thunkAPI) => {
    try {
      const response = await courseModuleService.getById(id);
      return { courseModule: response };
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setAlertError(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = {
  courseModules: [],
  courseModule: {},
  selectedModule: ''
};

export const courseModuleSlice = createSlice({
  name: 'courseModule',
  initialState,
  reducers: {
    setSelectedModule: (state, action) => {
      state.selectedModule = action.payload;
    }
  },
  extraReducers: {
    [getByCourse.pending]: (state) => {
      state.loading = true;
    },
    [getByCourse.fulfilled]: (state, action) => {
      state.loading = false;
      state.courseModules = action.payload.courseModules;
    },
    [getByCourse.rejected]: (state) => {
      state.loading = false;
    },
    [getCourseModuleById.pending]: (state) => {
      state.loading = true;
    },
    [getCourseModuleById.fulfilled]: (state, action) => {
      state.loading = false;
      state.courseModule = action.payload.courseModule;
    },
    [getCourseModuleById.rejected]: (state) => {
      state.loading = false;
    }
  }
});

export const { setSelectedModule } = courseModuleSlice.actions;

export default courseModuleSlice.reducer;
