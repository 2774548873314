import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setAlertError } from '../alert/alert.slice';
import { educationalVideosService } from './educationalVideos.service';

export const getAllPlayLists = createAsyncThunk(
  'educationalVideos/getAllPlayLists',
  async (data, thunkAPI) => {
    try {
      const response = await educationalVideosService.getAll();
      return { educationalVideos: response };
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setAlertError(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = {
  educationalVideos: [],
  selectedPlaylist: {},
  selectedVideo: {},
  loading: false
};

export const educationalVideosSlice = createSlice({
  name: 'playlist',
  initialState,
  reducers: {
    setSelectedPlaylist: (state, action) => {
      state.selectedPlaylist = action.payload;
    },
    setSelectedVideo: (state, action) => {
      state.selectedVideo = action.payload;
    }
  },
  extraReducers: {
    [getAllPlayLists.pending]: (state) => {
      state.loading = true;
    },
    [getAllPlayLists.fulfilled]: (state, action) => {
      state.loading = false;
      state.educationalVideos = action.payload.educationalVideos;
    },
    [getAllPlayLists.rejected]: (state) => {
      state.loading = false;
    }
  }
});

export const { setSelectedVideo, setSelectedPlaylist } = educationalVideosSlice.actions;

export default educationalVideosSlice.reducer;
