import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/system';

import {
  getAllCourses,
  getCourseById,
  setSelectedCourse,
  enrollCourse
} from '../../features/course/course.slice';
import CourseTile from './partial/courseTile';
import { useTitle } from '../../hoc/titleProvider';

function Courses() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const courses = useSelector((state) => state.course.courses);

  const { setTitle } = useTitle();
  setTitle('Courses');

  useEffect(() => {
    dispatch(getAllCourses());
  }, []);

  const handleClick = (course) => {
    dispatch(enrollCourse(course._id));
    dispatch(setSelectedCourse(course));

    navigate('/courses/modules');
  };

  return (
    <Box>
      {courses.map((course) => (
        <CourseTile key={course._id} course={course} onClick={() => handleClick(course)} />
      ))}
    </Box>
  );
}

export default Courses;
