import React, { useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { FairtreeTheme } from '../resources/theme';
import { useDispatch, useSelector } from 'react-redux';

import { BrowserRouter } from 'react-router-dom';
import MainRoutes from '../main.routes';

import { clearAlert } from '../features/alert/alert.slice';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import TitleProvider from '../hoc/titleProvider';

import LinkedInTag from 'react-linkedin-insight';
LinkedInTag.init('4483337', 'dc', false);

const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function App() {
  const { message, type, open } = useSelector((state) => state.alert);
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [mode, setMode] = useState(prefersDarkMode ? 'dark' : 'light');
  const theme = React.useMemo(() => FairtreeTheme(mode), [mode]);

  const dispatch = useDispatch();

  useEffect(() => {
    setMode(prefersDarkMode ? 'dark' : 'light');
  }, [prefersDarkMode]);

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      }
    }),
    []
  );

  const handleClose = (event, reason) => {
    dispatch(clearAlert());
  };

  return (
    <StyledEngineProvider injectFirst>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert onClose={handleClose} severity={type}>
              {message}
            </Alert>
          </Snackbar>
          <TitleProvider>
            <BrowserRouter>
              <MainRoutes />
            </BrowserRouter>
          </TitleProvider>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </StyledEngineProvider>
  );
}

export default App;
