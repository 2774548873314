import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setAlertSuccess, setAlertError } from '../alert/alert.slice';
import { formService } from './form.service';

export const getFormById = createAsyncThunk('form/getById', async (id, thunkAPI) => {
  try {
    const response = await formService.getById(id);
    return { form: response };
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    thunkAPI.dispatch(setAlertError(message));
    return thunkAPI.rejectWithValue();
  }
});

export const createFormSubmission = createAsyncThunk(
  'form/createSubmission',
  async (data, thunkAPI) => {
    try {
      const state = thunkAPI.getState();

      const response = await formService.createSubmition({
        formId: state.form.form._id,
        ...data
      });

      if (response.success) {
        setAlertSuccess(state.form.form.successMessage || 'Submitted Successfully');
      }

      return { success: response.success };
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setAlertError(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const submitResponse = createAsyncThunk('form/submitResponse', async (data, thunkAPI) => {
  try {
    const response = await formService.submit(data);

    if (response.success) {
      setAlertSuccess('Submitted Successfully');
    }

    return { success: response.success, score: response.response.score };
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    thunkAPI.dispatch(setAlertError(message));
    return thunkAPI.rejectWithValue();
  }
});

const initialState = {
  form: [],
  submition: {},
  loading: false
};

export const formSlice = createSlice({
  name: 'form',
  initialState,
  extraReducers: {
    [getFormById.pending]: (state) => {
      state.loading = true;
    },
    [getFormById.fulfilled]: (state, action) => {
      state.loading = false;
      state.form = action.payload.form;
    },
    [getFormById.rejected]: (state) => {
      state.loading = false;
    },

    [submitResponse.pending]: (state) => {
      state.loading = true;
    },
    [submitResponse.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = action.payload.success;
      state.score = action.payload.score;
    },
    [submitResponse.rejected]: (state) => {
      state.loading = false;
    },

    [createFormSubmission.pending]: (state) => {
      state.loading = true;
    },
    [createFormSubmission.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = action.payload.success;
    },
    [createFormSubmission.rejected]: (state) => {
      state.loading = false;
    }
  }
});

export default formSlice.reducer;
