import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';

// import UserVerification from "./components/account/userVerification";

import PrivateRoute from './hoc/privateRoute';
import PublicRoute from './hoc/publicRoute';
// import VerifyRoute from "./hoc/verifyRoute";

import { AuthRoutes } from './routes/auth.routes';
import { CourseRoutes } from './routes/course.routes';

import Courses from './components/courses';
import CourseModules from './components/courses/modules';
import CourseModule from './components/courses/modules/module';

import Vidoes from './components/shortVideos/playlists/index';
import PlaylistVidoes from './components/shortVideos/videos';

import SignIn from './components/account/signin';
import SignUp from './components/account/signup';
import ForgotPassword from './components/account/forgotPassword';
import UpdatePassword from './components/account/updatePassword';

import ReactGA from 'react-ga4';
import EmailVerification from './components/account/emailVerification';
import ContactUs from './components/contactus';
import Home from './components/home';
import Profile from './components/account/profile/profile';
ReactGA.initialize('G-LQY3F6T8M7', {
  gaOptions: { titleCase: false }
});

const private_route_group = [...CourseRoutes];
const public_route_group = [...AuthRoutes];

// const verify_route_group = [
//   {
//     path: "/user-verification",
//     component: UserVerification,
//     header: "Verification",
//   },
// ];

const MainRoutes = (props) => {
  const navigate = useNavigate();
  let location = useLocation();
  const auth = useSelector((state) => state.auth);

  React.useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location]);

  useEffect(() => {
    if (auth.loggedIn) {
      navigate('/');
    }
  }, [auth.loggedIn]);

  return (
    <Routes>
      <Route element={<PublicRoute />}>
        <Route path={'/signin'} element={<SignIn />} />
        <Route path={'/signup'} element={<SignUp />} />
        <Route path={'/forgot-password'} element={<ForgotPassword />} />
        <Route path={'/reset-password/:token'} element={<UpdatePassword />} />
        <Route path={'/verify/:token'} element={<EmailVerification />} />

        {/* {public_route_group.map((route, idx) => {
          return <Route key={idx} {...route} />;
        })} */}
      </Route>

      <Route element={<PrivateRoute />}>
        <Route path={'/'} element={<Home />} title={'Home'} />
        <Route path={'/user/profile'} element={<Profile />} title={'Your Profile'} />
        <Route path={'/contact'} element={<ContactUs />} title={'Contact'} />
        <Route path={'/courses'} element={<Courses />} title={'Courses'} />
        <Route
          path={'/short-videos'}
          element={<Vidoes />}
          title={'Investment Education Short Videos'}
        />
        <Route
          path={'/playlist'}
          element={<PlaylistVidoes />}
          title={'Investment Education Short Videos'}
        />
        <Route path={'/courses/modules'} element={<CourseModules />} title={'Course Modules'} />
        <Route path={'/courses/modules/module'} element={<CourseModule />} title={'Module'} />

        {/* {private_route_group.map((route, idx) => {
          return <Route key={idx} {...route}></Route>;
        })} */}
      </Route>

      {/* {verify_route_group.map((route, idx) => {
        return <VerifyRoute key={idx} {...route} />;
      })} */}

      {/* {public_route_group.map((route, idx) => {
        return <PublicRoute key={idx} {...route} />;
      })} */}
    </Routes>
  );
};

export default MainRoutes;
