import React from 'react';
import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 1, 2)
  }
}));

const PrimaryButton = ({ children, ...props }) => {
  const styles = useStyles();

  return (
    <Button variant="contained" color="primary" className={styles.root} {...props}>
      {children}
    </Button>
  );
};

export default PrimaryButton;
