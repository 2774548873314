import React from 'react';
import Logo from '../../resources/images/logos/LogoIconCopper.png';
import makeStyles from '@mui/styles/makeStyles';
import { Box } from '@mui/system';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${Logo})`,
    height: '50px',
    width: '50px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  },
  tagline: {}
}));

const SmallLogoIcon = () => {
  const classes = useStyles();

  return (
    <Box>
      <div className={classes.root}></div>
    </Box>
  );
};

export default SmallLogoIcon;
