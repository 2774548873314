import axios from 'axios';
import { authHeader } from '../helpers';

const apiClient = axios.create({
  baseURL: '/api/form',
  header: {
    'Content-Type': 'application/json'
  }
});

const getById = async (id) => {
  return apiClient.get(`/${id}`, { headers: { ...authHeader() } }).then((response) => {
    return response.data;
  });
};

const submit = async (data) => {
  return apiClient
    .post(`/${data.formId}/submit`, data, { headers: { ...authHeader() } })
    .then((response) => {
      return response.data;
    });
};

const createSubmition = async (data) => {
  return apiClient.post(`/submission`, data, { headers: { ...authHeader() } }).then((response) => {
    return response.data;
  });
};

export const formService = {
  getById,
  createSubmition,
  submit
};
