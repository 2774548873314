import { React, useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Dialog, DialogActions, DialogTitle } from '@mui/material';
import Typography from '@mui/material/Typography';
import { CardActionArea, CardActions } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedVideo } from '../../features/educationalVideos/educationalVideos.slice';
import PrimaryButton from '../ui/buttons/primaryButton';
import ReactPlayer from 'react-player/youtube';

import { Box } from '@mui/system';

function VideoTile(props) {
  const [player, setPlayer] = useState(false);
  const [url, setUrl] = useState('');
  const dispatch = useDispatch();

  const handlePlayer = (video) => {
    dispatch(setSelectedVideo(video));
    setPlayer(true);
    setUrl(video.link);
  };

  const handlePlayerClose = () => {
    setPlayer(false);
    setUrl('');
  };

  return (
    <>
      <Card
        sx={{
          m: 2,
          display: 'inline-block',
          minWidth: 320,
          maxWidth: 320
        }}
      >
        <CardActionArea onClick={() => handlePlayer(props.video)} sx={{}}>
          <CardMedia
            component="img"
            height="180"
            image={props.video.banner}
            sx={{ objectFit: 'contain' }}
          />
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              top: 0,
              width: '100%'
            }}
          ></Box>

          <CardContent>
            <Typography gutterBottom variant="h7" component="div">
              {props.video.title}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>

      {/* We do not want to add this to the card becuase the card us in a action area that will trigger when you click on it.
      We can maybe move this out to a different component. */}
      <Dialog open={player} onClose={handlePlayerClose} fullWidth maxWidth="md">
        <DialogTitle>{props.video.title}</DialogTitle>
        <ReactPlayer
          url={url}
          key={url}
          playing={player}
          loop={player}
          config={{
            youtube: {
              playerVars: {
                color: 'white',
                modestbranding: 1,
                controls: 1,
                showinfo: 1
              }
            }
          }}
          width={'100%'}
        />
        <DialogActions>
          <PrimaryButton onClick={() => handlePlayerClose()}>Close</PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default VideoTile;
