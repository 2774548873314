import axios from 'axios';
import { authHeader } from '../helpers';

const apiClient = axios.create({
  baseURL: '/api/courseModule',
  header: {
    'Content-Type': 'application/json'
  }
});

const getByCourse = async (courseId) => {
  return apiClient.get(`/course/${courseId}`, { headers: { ...authHeader() } }).then((response) => {
    return response.data;
  });
};

const getById = async (id) => {
  return apiClient.get(`/${id}`, { headers: { ...authHeader() } }).then((response) => {
    return response.data;
  });
};

export const courseModuleService = {
  getByCourse,
  getById
};
