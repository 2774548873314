import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useFieldArray, useForm } from 'react-hook-form';

import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  MenuItem,
  Paper,
  Radio,
  Typography,
  useTheme
} from '@mui/material';
import PrimaryButton from '../ui/buttons/primaryButton';
import Form from '../ui/forms';
import Input from '../ui/forms/fields/input';
import Select from '../ui/forms/fields/select';
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import { getFormById, submitResponse } from '../../features/form/form.slice';
import CheckboxList from '../ui/forms/fields/checkboxGroup';
import CustomRadioGroup from '../ui/forms/fields/radioGroup';
import { Box } from '@mui/system';
import ScaleSlider from '../ui/forms/fields/scaleSlider';
import OrangeProgress from '../ui/controls/circularProgress';

function DynamicForm({ formId, onFormSubmit, onFormResult }) {
  const {
    reset,
    control,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onBlur'
  });
  const dispatch = useDispatch();
  const theme = useTheme();

  const form = useSelector((state) => state.form.form);
  const fieldArray = useSelector((state) => state.form.form.formFields);
  const loading = useSelector((state) => state.form.loading);
  const score = useSelector((state) => state.form.score);

  const [open, setOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const { fields, append } = useFieldArray({
    control,
    name: formId
  });

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  useEffect(() => {
    dispatch(getFormById(formId));
  }, []);

  useEffect(() => {
    if (fieldArray) {
      reset();
      append(fieldArray);
    }
  }, [fieldArray]);

  const onSubmit = (data) => {
    setSubmitted(true);

    data.formId = form._id;
    dispatch(submitResponse(data))
      .then((res) => {
        if (res.payload.success) onFormSubmit(res.payload.score);
      })
      .catch((err) => {});
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRedo = () => {
    setSubmitted(false);
    reset();
    append(fieldArray);
  };

  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const getForm = () => (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {fields &&
        fields.map((item, index) => {
          switch (item.field.fieldType) {
            case 'title-and-description':
              return (
                <Paper key={index} sx={{ my: 2, p: 2 }} elevation={3}>
                  <Typography variant="h4" gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography variant="p" gutterBottom>
                    {item.description}
                  </Typography>
                </Paper>
              );
            case 'text':
              return (
                <Paper
                  key={index}
                  elevation={3}
                  sx={{
                    my: 2,
                    p: 2,
                    border: errors[item.slug] ? `solid 1px ${theme.palette.error.main}` : 'inherit'
                  }}
                >
                  <Typography variant="h4" sx={{ mb: 2 }}>
                    {item.question}
                  </Typography>
                  <Input
                    label={'Answer'}
                    error={!!errors[item.slug]}
                    helperText={errors[item.slug]?.message}
                    {...register(item.slug, item.validation)}
                  />
                </Paper>
              );
            case 'scale':
              return (
                <Paper
                  key={index}
                  elevation={3}
                  sx={{
                    my: 2,
                    p: 2,
                    border: errors[item.slug] ? `solid 1px ${theme.palette.error.main}` : 'inherit'
                  }}
                >
                  <Typography variant="h4" sx={{ mb: 2 }}>
                    {item.question}
                  </Typography>
                  <ScaleSlider
                    control={control}
                    rules={item.validation}
                    max={item.scale.max}
                    startText={`1 - ${item.scale.startText}`}
                    endText={`${item.scale.max} - ${item.scale.endText}`}
                    error={!!errors[item.slug]}
                    helperText={errors[item.slug]?.message}
                    {...register(item.slug, item.validation)}
                  />
                </Paper>
              );
            case 'select':
              return (
                <Paper
                  key={index}
                  elevation={3}
                  sx={{
                    my: 2,
                    p: 2,
                    border: errors[item.slug] ? `solid 1px ${theme.palette.error.main}` : 'inherit'
                  }}
                >
                  <Select
                    name={item.slug}
                    label={item.question}
                    error={!!errors[item.slug]}
                    helperText={errors[item.slug]?.message}
                    control={control}
                    rules={item.validation}
                  >
                    {item.options &&
                      item.options.map((opt, index) => (
                        <MenuItem key={index} value={opt.value}>
                          {opt.text}
                        </MenuItem>
                      ))}
                  </Select>
                </Paper>
              );
            case 'radio-list':
              return (
                <Paper
                  key={index}
                  elevation={3}
                  sx={{
                    my: 2,
                    p: 2,
                    border: errors[item.slug] ? `solid 1px ${theme.palette.error.main}` : 'inherit'
                  }}
                >
                  <Typography variant="h4" sx={{ mb: 2 }}>
                    {item.question}
                  </Typography>
                  <CustomRadioGroup
                    name={item.slug}
                    label={item.question}
                    error={!!errors[item.slug]}
                    helperText={errors[item.slug]?.message}
                    control={control}
                    rules={item.validation}
                  >
                    {item.options.map((radio, index) => (
                      <FormControlLabel
                        sx={{ mb: '10px' }}
                        key={index}
                        value={radio.value}
                        control={<Radio />}
                        label={radio.text}
                      />
                    ))}
                  </CustomRadioGroup>
                </Paper>
              );
            case 'checkbox-list':
              return (
                <Paper
                  key={index}
                  elevation={3}
                  sx={{
                    my: 2,
                    p: 2,
                    border: errors[item.slug] ? `solid 1px ${theme.palette.error.main}` : 'inherit'
                  }}
                >
                  <Typography variant="h4" sx={{ mb: 2 }}>
                    {item.question}
                  </Typography>
                  <CheckboxList
                    name={item.slug}
                    label={item.question}
                    error={!!errors[item.slug]}
                    helperText={errors[item.slug]?.message}
                    control={control}
                    options={item.options}
                    rules={item.validation}
                  />
                </Paper>
              );
            default:
          }
        })}
      <br />
      <Box display={'flex'} flexDirection={'column'} alignItems="center">
        <PrimaryButton type="submit">Submit</PrimaryButton>
      </Box>
    </Form>
  );

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: { backgroundColor: theme.palette.background.default }
        }}
        fullWidth={true}
        maxWidth={'md'}
        fullScreen={fullScreen}
      >
        <DialogContent>
          <IconButton
            sx={{
              position: 'absolute',
              right: 25,
              top: 15
            }}
            color="primary"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Box display="flex" flexDirection={'column'} alignItems={'center'} sx={{ mt: 2, mb: 5 }}>
            <Typography variant={'h1'} sx={{ mb: 1 }}>
              {form.header}
            </Typography>
            <Typography variant={'h4'}>{form.name}</Typography>
          </Box>
          {!loading ? (
            !submitted ? (
              getForm()
            ) : form.isQuiz && score ? (
              <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                {score.pass ? (
                  <>
                    <Typography variant="h2" gutterBottom textAlign={'center'} sx={{ mb: 2 }}>
                      Congratulations, you have passed!
                    </Typography>
                    <Typography variant="h4" gutterBottom>
                      Your scored
                    </Typography>
                    <Typography variant="h1" gutterBottom>
                      {score.percentage}%
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography variant="h3" gutterBottom textAlign={'center'} sx={{ mb: 2 }}>
                      Thank you for your submission, unfortunately you have not passed. Please try
                      again. There is no limitation on how many times you can attempt the quiz.
                    </Typography>
                    <Typography variant="h4" gutterBottom>
                      Your scored
                    </Typography>
                    <Typography variant="h1" gutterBottom>
                      {score.percentage}%
                    </Typography>
                  </>
                )}

                <PrimaryButton variant={'outlined'} onClick={handleRedo}>
                  Redo Quiz
                </PrimaryButton>
                <PrimaryButton onClick={handleClose}>Close</PrimaryButton>
              </Box>
            ) : (
              <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                <Typography variant="h2" gutterBottom textAlign={'center'} sx={{ mb: 2 }}>
                  Thank you for your submission.
                </Typography>
                <PrimaryButton variant={'outlined'} onClick={handleRedo}>
                  Resubmit Form
                </PrimaryButton>
                <PrimaryButton onClick={handleClose}>Close</PrimaryButton>
              </Box>
            )
          ) : (
            <OrangeProgress caption="Loading..." />
          )}
        </DialogContent>
      </Dialog>
      <PrimaryButton onClick={handleOpen}>Start</PrimaryButton>
    </>
  );
}

export default DynamicForm;
