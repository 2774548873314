import React from 'react';
import DarkLogo from '../../resources/images/logos/FullColourLogoBlackTransparent.png';
import LightLogo from '../../resources/images/logos/FullColourLogo.png';
import makeStyles from '@mui/styles/makeStyles';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${theme.palette.mode === 'dark' ? DarkLogo : LightLogo})`,
    height: '100px',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    margin: '10px'
  },
  tagline: {
    fontSize: '14px',
    marginTop: '10px',
    marginLeft: '30px'
  }
}));

const HorizontalLogoWithSlogan = () => {
  const classes = useStyles();

  return (
    <Box sx={{ mb: 2 }}>
      <div className={classes.root}></div>
      {/* <Typography className={classes.tagline}>Navigate Platform</Typography> */}
    </Box>
  );
};

export default HorizontalLogoWithSlogan;
