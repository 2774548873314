import { createTheme } from '@mui/material/styles';

import GothamBold from './fonts/GothamBold.ttf';
import GothamBook from './fonts/GothamBook.ttf';
import GothamLight from './fonts/GothamLight.ttf';
import GothamMedium from './fonts/GothamMedium.ttf';
import RecifeDisplayTrialBlack from './fonts/RecifeDisplayTrialBlack.ttf';
import RecifeDisplayTrialBold from './fonts/RecifeDisplayTrialBold.ttf';
import RecifeDisplayTrialMedium from './fonts/RecifeDisplayTrialMedium.ttf';
import RecifeDisplayTrialRegular from './fonts/RecifeDisplayTrialRegular.ttf';

const FairtreeTheme = (mode) =>
  createTheme({
    palette: {
      mode,
      ...(mode === 'light'
        ? {
            primary: {
              main: '#CC6323'
            },
            secondary: {
              main: '#424242'
            },
            background: {
              default: '#f5f0eb', //ede9e4
              paper: '#DEDAD4'
            }
          }
        : {
            primary: {
              main: '#CC6323'
            },
            secondary: {
              main: '#424242'
            },
            background: {
              default: '#231F20',
              paper: '#2E2A2B'
            }
          })
    },
    typography: {
      fontFamily: 'Gotham, Arial',
      fontSize: 10,
      h1: {
        fontSize: '2.5em',
        fontWeight: '400'
      },
      h2: {
        fontSize: '2.0em'
      },
      h3: {
        fontSize: '1.7em'
      },
      h4: {
        fontSize: '1.4em'
      },
      h5: {
        fontSize: '1.2em'
      },
      h6: {
        fontSize: '1em'
      }
    },
    components: {
      MuiPaper: {
        styleOverrides: { root: { backgroundImage: 'unset' } }
      },
      MuiAppBar: {
        defaultProps: {
          enableColorOnDark: true
        }
      },
      MuiCssBaseline: {
        styleOverrides: `
        @font-face {
          font-family: 'Gotham';
          font-style: normal;
          font-display: swap;
          font-weight: 200;
          src: local('Gotham Light'), local('Gotham-Light'), url(${GothamLight}) format('truetype');
        unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;        
        }

        @font-face {
          font-family: 'Gotham';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Gotham Book'), local('Gotham-Book'), url(${GothamBook}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }

        @font-face {
          font-family: 'Gotham';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: local('Gotham Medium'), local('Gotham-Medium'), url(${GothamMedium}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }

        @font-face {
          font-family: 'Gotham';
          font-style: normal;
          font-display: swap;
          font-weight: 600;
          src: local('Gotham Bold'), local('Gotham-Bold'), url(${GothamBold}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }

        @font-face {
          font-family: 'RecifeDisplayTrial';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Recife Display Trial'), local('RecifeDisplayTrial-Regular'), url(${RecifeDisplayTrialRegular}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }

        @font-face {
          font-family: 'RecifeDisplayTrial';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: local('Recife Display Trial Black'), local('RecifeDisplayTrial-Black'), url(${RecifeDisplayTrialBlack}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }

        @font-face {
          font-family: 'RecifeDisplayTrial';
          font-style: normal;
          font-display: swap;
          font-weight: 600;
          src: local('Recife Display Trial Medium'), local('RecifeDisplayTrial-Medium'), url(${RecifeDisplayTrialMedium}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }

        @font-face {
          font-family: 'RecifeDisplayTrial';
          font-style: normal;
          font-display: swap;
          font-weight: 700;
          src: local('Recife Display Trial'), local('RecifeDisplayTrial-Bold'), url(${RecifeDisplayTrialBold}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `
      }
    }
  });

export { FairtreeTheme };
