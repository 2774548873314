import React from 'react';
import SignInForm from './partials/signInForm';
import LoginLayout from './loginLayout';
import { Typography } from '@mui/material';
import { useTitle } from '../../hoc/titleProvider';
import { Box } from '@mui/system';

export default function SignIn() {
  const { setTitle } = useTitle();
  setTitle('Sign In');

  return (
    <LoginLayout>
      <Box>
        <Typography sx={{ ml: 4, fontSize: '30px', fontFamily: 'RecifeDisplayTrial' }}>
          Navigate Platform
        </Typography>
        <Typography sx={{ ml: 4, width: 280 }}>
          Welcome to a world of investment information to equip and empower you to make wise
          financial decisions.
        </Typography>
      </Box>
      <SignInForm />
    </LoginLayout>
  );
}
