// import EmailVerification from "../components/account/emailVerification";
// import ForgotPassword from "../components/account/forgotPassword";
import EmailVerification from '../components/account/emailVerification';
import SignIn from '../components/account/signin';
import SignUp from '../components/account/signup';

// import UpdatePassword from "../components/account/updatePassword";

export const AuthRoutes = [
  { path: '/verify/:token', element: <EmailVerification /> },
  { path: '/signin', element: <SignIn /> },
  { path: '/signup', element: <SignUp /> },
  { path: '/', element: <SignIn /> }

  // { path: "/forgot-password", component: ForgotPassword, exact: true },
  // { path: "/reset-password/:token", component: UpdatePassword, exact: true },
  // {
  //   path: "/verify/:token",
  //   redirectAdmin: false,
  //   component: EmailVerification,
  // },
];
