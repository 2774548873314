import React from 'react';
import LoginLayout from './loginLayout';
import UpdatePasswordForm from './partials/updatePasswordForm';
import { useParams } from 'react-router-dom';
import { useTitle } from '../../hoc/titleProvider';

function UpdatePassword(props) {
  const { setTitle } = useTitle();
  setTitle('Update Password');

  const params = useParams();
  const token = params.token ? params.token : null;

  return (
    <LoginLayout>
      <UpdatePasswordForm token={token} />
    </LoginLayout>
  );
}

export default UpdatePassword;
