import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import GeneralButton from '../ui/buttons/generalButton';
import { Box, Container, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { verifyEmailAddress } from '../../features/auth/auth.slice';
import OrangeCircularProgress from '../ui/controls/circularProgress';
import { useTitle } from '../../hoc/titleProvider';

const useStyles = makeStyles((theme) => ({
  root: {},
  header: {
    textAlign: 'center'
  },
  text: {
    textAlign: 'center'
  },
  button: {
    margin: theme.spacing(2)
  },
  input: {
    margin: theme.spacing(2)
  }
}));

const EmailVerification = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const { setTitle } = useTitle();
  setTitle('Email Verification');

  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    const token = params.token;
    dispatch(verifyEmailAddress(token));
  }, []);

  const handleContitnue = () => {
    navigate('/signin');
  };

  return (
    <Container maxWidth="sm">
      {!auth.loading ? (
        <Box display="flex" flexDirection="col" justifyContent="center" alignItems="center">
          {auth.success && !auth.error ? (
            <Typography className={classes.header}>
              Thank you, your email has been verifeid.
            </Typography>
          ) : (
            <GeneralButton className={classes.button} onClick={handleContitnue}>
              Continue
            </GeneralButton>
          )}
        </Box>
      ) : (
        <OrangeCircularProgress caption="Email address is currently being verified. Please wait." />
      )}

      {!auth.loading && auth.success ? (
        <GeneralButton className={classes.button} onClick={handleContitnue}>
          Continue
        </GeneralButton>
      ) : null}
    </Container>
  );
};

export default EmailVerification;
