import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setAlertSuccess, setAlertError } from '../alert/alert.slice';
import { courseService } from './course.service';

export const getAllCourses = createAsyncThunk('course/getAllCourses', async (data, thunkAPI) => {
  try {
    const response = await courseService.getAll();
    return { courses: response };
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    thunkAPI.dispatch(setAlertError(message));
    return thunkAPI.rejectWithValue();
  }
});

export const getCourseById = createAsyncThunk('course/getById', async (id, thunkAPI) => {
  try {
    const response = await courseService.getById(id);
    return { course: response };
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    thunkAPI.dispatch(setAlertError(message));
    return thunkAPI.rejectWithValue();
  }
});

export const enrollCourse = createAsyncThunk('course/enroll', async (id, thunkAPI) => {
  try {
    const response = await courseService.enroll(id);
    return { enroll: response };
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    thunkAPI.dispatch(setAlertError(message));
    return thunkAPI.rejectWithValue();
  }
});

export const getCourseProgress = createAsyncThunk('course/getProgress', async (id, thunkAPI) => {
  try {
    const response = await courseService.getProgress(id);
    return { progress: response.userCourse };
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    thunkAPI.dispatch(setAlertError(message));
    return thunkAPI.rejectWithValue();
  }
});

export const getRequestCertificate = createAsyncThunk(
  'course/getRequestCertificate',
  async (id, thunkAPI) => {
    try {
      const response = await courseService.getRequestCertificate(id);
      if (response.success)
        thunkAPI.dispatch(
          setAlertSuccess(
            'Thank you, your request has been submitted and someone from the Navigate team will tend to it shortly.'
          )
        );
      return { success: response.success };
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setAlertError(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateCourseSection = createAsyncThunk(
  'course/updateSection',
  async (data, thunkAPI) => {
    try {
      const state = thunkAPI.getState();

      const response = await courseService.updateSection(
        state.course.selectedCourse._id,
        state.courseModule.selectedModule._id,
        data.sectionId,
        data.update
      );
      return { progress: response.courseUser };
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setAlertError(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = {
  courses: [],
  course: {},
  progress: {},
  selectedCourse: {},
  loading: false
};

export const courseSlice = createSlice({
  name: 'course',
  initialState,
  reducers: {
    setSelectedCourse: (state, action) => {
      state.selectedCourse = action.payload;
    }
  },
  extraReducers: {
    [getAllCourses.pending]: (state) => {
      state.loading = true;
    },
    [getAllCourses.fulfilled]: (state, action) => {
      state.loading = false;
      state.courses = action.payload.courses;
    },
    [getAllCourses.rejected]: (state) => {
      state.loading = false;
    },
    [getCourseById.pending]: (state) => {
      state.loading = true;
    },
    [getCourseById.fulfilled]: (state, action) => {
      state.loading = false;
      state.course = action.payload.course;
    },
    [getCourseById.rejected]: (state) => {
      state.loading = false;
    },

    [enrollCourse.pending]: (state) => {
      state.loading = true;
    },
    [enrollCourse.fulfilled]: (state) => {
      state.loading = false;
    },
    [enrollCourse.rejected]: (state) => {
      state.loading = false;
    },

    [getCourseProgress.pending]: (state) => {
      state.loading = true;
    },
    [getCourseProgress.fulfilled]: (state, action) => {
      state.loading = false;
      state.progress = action.payload.progress;
    },
    [getCourseProgress.rejected]: (state) => {
      state.loading = false;
    },

    [updateCourseSection.pending]: (state) => {
      state.loading = true;
    },
    [updateCourseSection.fulfilled]: (state, action) => {
      state.loading = false;
      state.progress = action.payload.progress;
    },
    [updateCourseSection.rejected]: (state) => {
      state.loading = false;
    },

    [getRequestCertificate.pending]: (state) => {
      state.requestloading = true;
    },
    [getRequestCertificate.fulfilled]: (state, action) => {
      state.requestloading = false;
      state.requestCertSuccess = action.payload.success;
    },
    [getRequestCertificate.rejected]: (state) => {
      state.requestloading = false;
      state.requestCertSuccess = false;
    }
  }
});

export const { setSelectedCourse } = courseSlice.actions;

export default courseSlice.reducer;
