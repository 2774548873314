import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Form from '../ui/forms';
import Input from '../ui/forms/fields/input';
import Checkbox from '../ui/forms/fields/checkbox';
import Select from '../ui/forms/fields/select';
import PrimaryButton from '../ui/buttons/primaryButton';
import { MenuItem, Dialog, DialogActions, DialogTitle, DialogContent } from '@mui/material';
import { getByCourse } from '../../features/courseModule/courseModule.slice';
import { createFormSubmission, getFormById } from '../../features/form/form.slice';
import { getAllCourses } from '../../features/course/course.slice';

function ContactForm() {
  const dispatch = useDispatch();
  const [openSuccess, setOpenSuccess] = useState(false);
  const schemaValidation = yup.object().shape({
    name: yup.string().required('Please enter a name.'),
    email: yup
      .string()
      .email('Please enter a valid email address.')
      .required('Please enter an email address.'),
    contactNumber: yup.string(),
    natureOfQuery: yup.string().required('Please select one.'),
    courseName: yup.string(),
    coursePart: yup.string(),
    query: yup.string().required('Please provide us some details around your query.'),
    marketing: yup.bool()
  });

  const {
    reset,
    control,
    watch,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schemaValidation)
  });

  const courses = useSelector((state) => state.course.courses);
  const courseModules = useSelector((state) => state.courseModule.courseModules);

  useEffect(() => {
    dispatch(getAllCourses());
    dispatch(getFormById('621e0d1103f54312987e674a'));
  }, [dispatch]);

  const courseWatch = watch('courseName');
  useEffect(() => {
    if (courseWatch) {
      dispatch(getByCourse(courseWatch));
    }
  }, [courseWatch, dispatch]);

  const onSubmit = (data) => {
    dispatch(createFormSubmission(data))
      .unwrap()
      .then((originalPromiseResult) => {
        handleSuccess();
        reset();
      })
      .catch((error) => {});
  };

  const handleSuccess = () => {
    setOpenSuccess(true);
  };

  const handleSuccessClose = () => {
    setOpenSuccess(false);
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input
          label="Name"
          error={!!errors.name}
          helperText={errors?.name?.message}
          {...register('name')}
        />
        <Input
          label="Email"
          error={!!errors.email}
          helperText={errors?.email?.message}
          {...register('email')}
        />
        <Input
          label="Contact Details"
          error={!!errors.contactNumber}
          helperText={errors?.contactNumber?.message || 'If you would like us to call you'}
          {...register('contactNumber')}
        />
        <Select
          name="natureOfQuery"
          label="Nature of your query"
          error={!!errors.natureOfQuery}
          helperText={errors?.natureOfQuery?.message}
          control={control}
        >
          <MenuItem value="I’d like to Invest">I’d like to Invest</MenuItem>
          <MenuItem value="Query about a Course/Module">Query about a Course/Module</MenuItem>
        </Select>

        {courses && watch('natureOfQuery') === 'Query about a Course/Module' && (
          <>
            <Select
              name="courseName"
              label="Course"
              error={!!errors.courseName}
              helperText={errors?.courseName?.message}
              control={control}
            >
              {courses.map((course) => (
                <MenuItem value={course._id}>{course.name}</MenuItem>
              ))}
            </Select>

            {watch('courseName') && (
              <Select
                name="coursePart"
                label="Part"
                error={!!errors.coursePart}
                helperText={errors?.coursePart?.message}
                control={control}
              >
                {courseModules &&
                  courseModules.map((courseModule) => (
                    <MenuItem value={courseModule._id} disabled={!courseModule.active}>
                      {courseModule.name}
                    </MenuItem>
                  ))}
              </Select>
            )}
          </>
        )}

        <Input
          label="Please type your query here"
          error={!!errors.query}
          helperText={errors?.query?.message}
          multiline
          rows={4}
          {...register('query')}
        />

        <Checkbox
          name="marketing"
          label="I would like to receive updates about courses, products and other news from Fairtree."
          control={control}
        />

        <br />
        <PrimaryButton type="submit">Submit</PrimaryButton>
      </Form>
      <Dialog open={openSuccess} onClose={handleSuccessClose}>
        <DialogTitle>Success</DialogTitle>
        <DialogContent>
          Thank you for submitting your query. We have received it and one of our Team Navigate
          members will respond within the next 2 business days.
        </DialogContent>
        <DialogActions>
          <PrimaryButton onClick={handleSuccessClose}>Close</PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ContactForm;
