import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { makeStyles } from '@mui/styles';
import PrimaryButton from '../../ui/buttons/primaryButton';
import Form from '../../ui/forms';
import Checkbox from '../../ui/forms/fields/checkbox';
import Input from '../../ui/forms/fields/input';
import GreatDatePicker from '../../ui/forms/fields/datePicker';

import { useDispatch } from 'react-redux';
import { register as userRegister } from '../../../features/auth/auth.slice';
import { Box } from '@mui/system';
import { Divider, Grid, Link, Typography } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 0, 2, 5),
    maxWidth: '250px',
    height: '50%'
  }
}));

const schema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required('Email is required.')
    .email('This does not look like a email address.'),
  firstName: yup.string().required('Please enter your first name.'),
  lastName: yup.string().required('Please enter your last name.'),
  phoneNumber: yup.string().required('Please enter your phone number.'),
  dob: yup.date().required('Please select a date of birth.'),
  password: yup
    .string()
    .min(6, 'Password must be at least 6 characters')
    .required('Password is required.'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
  acceptTC: yup.bool().oneOf([true], 'Accept Ts & Cs is required')
});

function SignUpForm() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema)
  });

  const onSubmit = (data) => {
    dispatch(userRegister(data));
  };

  return (
    <Box className={classes.root} display="flex" flexDirection="column" justifyContent="center">
      <Typography variant="h5">Sign up</Typography>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input
          label="First Name"
          autoComplete="firstName"
          autoFocus
          error={!!errors.firstName}
          helperText={errors?.firstName?.message}
          {...register('firstName')}
        />
        <Input
          label="Last Name"
          autoComplete="lastName"
          error={!!errors.lastName}
          helperText={errors?.lastName?.message}
          {...register('lastName')}
        />
        <GreatDatePicker
          fullWidth
          id="dob"
          name="dob"
          label="Date of Birth"
          control={control}
          defaultValue={new Date()}
          error={!!errors.entityType}
        />

        <Input
          label="Email Address"
          autoComplete="email"
          error={!!errors.email}
          helperText={errors?.email?.message}
          {...register('email')}
        />
        <Input
          label="Phone Number (International Format)"
          autoComplete="phoneNumber"
          error={!!errors.phoneNumber}
          helperText={errors?.phoneNumber?.message}
          {...register('phoneNumber')}
        />

        <Input
          label="Password"
          type="password"
          autoComplete="current-password"
          error={!!errors.password}
          helperText={errors?.password?.message}
          {...register('password')}
        />
        <Input
          label="Confirm Password"
          type="password"
          autoComplete="current-password"
          error={!!errors.confirmPassword}
          helperText={errors?.confirmPassword?.message}
          {...register('confirmPassword')}
        />

        <Divider sx={{ m: 3 }} />
        <Link href="https://fairtree.com/disclaimer/" target="_blank" variant="body2">
          {'Disclaimer | '}
        </Link>
        <Link
          href="https://fairtree.com/wp-content/uploads/2021/01/FAM-PAIA-Manual-202001.pdf"
          target="_blank"
          variant="body2"
        >
          {'PAIA Manual | '}
        </Link>
        <Link
          href="https://fairtree.com/wp-content/uploads/2021/06/FAM-Privacy-Notice-07-06-2021.pdf"
          target="_blank"
          variant="body2"
        >
          {'Privacy Notice'}
        </Link>
        <Checkbox
          control={control}
          name="acceptTC"
          label="I have read and accept the Privacy Notice, Disclaimer and PAIA Manual"
        />

        <PrimaryButton type={'submit'}>Sign Up</PrimaryButton>

        <Grid container>
          <Grid item>
            <Link href="/signin" variant="body2">
              {'Already signed up? Sign in here'}
            </Link>
          </Grid>
        </Grid>
      </Form>
    </Box>
  );
}

export default SignUpForm;
