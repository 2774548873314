import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Link,
  Typography,
  useMediaQuery
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import React, { Fragment, useState } from 'react';
import PrimaryButton from './buttons/primaryButton';

import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { Box } from '@mui/system';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function PDFViewerDialog(props) {
  //https://www.npmjs.com/package/react-pdf
  //https://levelup.gitconnected.com/displaying-pdf-in-react-app-6e9d1fffa1a9
  //https://github.com/wojtekmaj/react-pdf/issues/680
  const [numPages, setNumPages] = useState(null);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('md'));

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  let { pdf } = props;

  const onClose = () => {
    props.onClose();
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} fullScreen={!isLargeScreen} maxWidth={'lg'}>
      <DialogTitle>
        {props.title}
        {props.onClose ? (
          <IconButton
            aria-label="close"
            onClick={props.onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>

      <DialogContent>
        <Box display="flex" justifyContent={'center'} alignItems={'center'}>
          <Document
            file={pdf}
            onLoadSuccess={onDocumentLoadSuccess}
            externalLinkRel={'_self'}
            externalLinkTarget={'_blank'}
            renderMode={'canvas'}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Fragment>
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  scale={isMobile ? 0.45 : 1}
                  renderTextLayer={false}
                  render
                  renderAnnotationLayer={true}
                  onGetAnnotationsSuccess={(result) => console.log(result)}
                />

                {index + 1 !== numPages && <Divider sx={{ margin: 1 }} />}
              </Fragment>
            ))}
          </Document>
        </Box>
      </DialogContent>

      <DialogActions>
        <Typography>
          Should you wish to print / Having trouble viewing this document{' '}
          <Link href={pdf} target="_blank">
            Click here
          </Link>
        </Typography>

        <PrimaryButton onClick={onClose}>Close</PrimaryButton>
      </DialogActions>
    </Dialog>
  );
}

export default PDFViewerDialog;
