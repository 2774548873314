import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';
import CourseModuleTile from '../partial/courseModuleTile';
import { getByCourse, setSelectedModule } from '../../../features/courseModule/courseModule.slice';
import { Typography, Grid } from '@mui/material';
import AlertButton from '../../ui/buttons/alertButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { getCourseProgress, getRequestCertificate } from '../../../features/course/course.slice';
import PDFViewerDialog from '../../ui/pdfViewerDialog';
import { useTitle } from '../../../hoc/titleProvider';
import PrimaryButton from '../../ui/buttons/primaryButton';
import VideoPlayer from '../../ui/videoPlayer';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

function CourseModules() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isCompleted, setCompleted] = useState(false);
  const [pdfOpen, setPdfOpen] = useState(false);
  const [pdfOpenInstructions, setPdfOpenInstructions] = useState(false);
  const [player, setPlayer] = useState(false);

  const course = useSelector((state) => state.course.selectedCourse);
  const courseModules = useSelector((state) => state.courseModule.courseModules);

  const progress = useSelector((state) => state.user.courseProgress);

  const { setTitle } = useTitle();

  useEffect(() => {
    if (course._id) {
      setTitle(course.name + ' - Modules');
      dispatch(getByCourse(course._id));
      dispatch(getCourseProgress(course._id));
    }
  }, [course, dispatch]);

  useEffect(() => {
    progress &&
      progress.forEach((item) => {
        if (item.id === course._id && item.completedModules === item.totalModules) {
          setCompleted(true);
        }
      });
  }, [progress, isCompleted]);

  const handleCertificateRequest = () => {
    dispatch(getRequestCertificate(course._id));
  };

  const handleClick = (courseModule) => {
    dispatch(setSelectedModule(courseModule));
    navigate('/courses/modules/module');
  };

  const handlePdfOpen = () => {
    setPdfOpen(true);
  };

  const handlePdfClose = () => {
    setPdfOpen(false);
  };

  const handlePdfOpenInst = () => {
    setPdfOpenInstructions(true);
  };

  const handlePdfCloseInst = () => {
    setPdfOpenInstructions(false);
  };
  const handlePlayer = () => {
    setPlayer(true);
  };
  const handleClosePlayer = (sectionId) => {
    setPlayer(false);
  };

  return (
    <Box>
      {course.competition && (
        <>
          <Grid
            container
            spacing={2}
            sx={{
              textAlign: 'center',
              paddingBottom: '16px',
              paddingRight: '16px',
              backgroundColor: 'rgb(21, 12, 7)',
              color: 'rgb(238, 205, 184);'
            }}
          >
            <Grid item xs={1} md={1}>
              <InfoOutlinedIcon color="primary" />
            </Grid>
            <Grid item xs={11} md={7}>
              {course.competition.description}
            </Grid>
            <Grid item xs={12} md={4}>
              <Box>
                {course.competition.competitionWinnerVideo && (
                  <AlertButton
                    size="small"
                    variant="outlined"
                    onClick={handlePlayer}
                    sx={{ mr: 1 }}
                  >
                    View Winner
                  </AlertButton>
                )}
                <AlertButton size="small" variant="outlined" onClick={handlePdfOpen} sx={{ mr: 1 }}>
                  Terms and Conditions
                </AlertButton>

                <PDFViewerDialog
                  open={pdfOpen}
                  onClose={() => handlePdfClose()}
                  pdf={course.competition.termsLink}
                  title={'Terms & Conditions'}
                />

                {course.competition.instructions && (
                  <>
                    <AlertButton
                      size="small"
                      variant="outlined"
                      onClick={handlePdfOpenInst}
                      sx={{ mr: 1 }}
                    >
                      Competition Details
                    </AlertButton>
                    <PDFViewerDialog
                      open={pdfOpenInstructions}
                      onClose={() => handlePdfCloseInst()}
                      pdf={course.competition.instructions}
                      title={'Competition Details'}
                    />
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
          <VideoPlayer
            open={player}
            onClose={() => handleClosePlayer()}
            title={course.competition.competitionWinnerVideoTitle}
            videoId={course.competition.competitionWinnerVideo}
          />
        </>
      )}
      {isCompleted && (
        <PrimaryButton
          onClick={() => {
            handleCertificateRequest();
          }}
        >
          Request Certificate of Completion
        </PrimaryButton>
      )}
      <div>
        {!course.rowLayout ? (
          courseModules.map((item, index) => (
            <CourseModuleTile key={item._id} course={item} onClick={() => handleClick(item)} />
          ))
        ) : (
          <Box>
            <Box>
              <Typography sx={{ marginTop: 3 }} variant="h2"></Typography>
              {courseModules
                .filter((cm) => cm.row === undefined)
                .map((item, index) => (
                  <CourseModuleTile
                    key={item._id}
                    course={item}
                    onClick={() => handleClick(item)}
                  />
                ))}
            </Box>
            <Box>
              <Typography sx={{ marginTop: 3 }} variant="h2">
                {course.rowHeadings[0]}
              </Typography>
              {courseModules
                .filter((cm) => cm.row && cm.row === 1)
                .map((item, index) => (
                  <CourseModuleTile
                    key={item._id}
                    course={item}
                    onClick={() => handleClick(item)}
                  />
                ))}
            </Box>
            <Box>
              <Typography sx={{ marginTop: 3 }} variant="h2">
                {course.rowHeadings[1]}
              </Typography>
              {courseModules
                .filter((cm) => cm.row && cm.row === 2)
                .map((item, index) => (
                  <CourseModuleTile
                    key={item._id}
                    course={item}
                    onClick={() => handleClick(item)}
                  />
                ))}
            </Box>
            <Box>
              <Typography sx={{ marginTop: 3 }} variant="h2">
                {course.rowHeadings[2]}
              </Typography>
              {courseModules
                .filter((cm) => cm.row && cm.row === 3)
                .map((item, index) => (
                  <CourseModuleTile
                    key={item._id}
                    course={item}
                    onClick={() => handleClick(item)}
                  />
                ))}
            </Box>
          </Box>
        )}
      </div>
    </Box>
  );
}

export default CourseModules;
