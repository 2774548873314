import React from 'react';
import { useTitle } from '../../hoc/titleProvider';
import LoginLayout from './loginLayout';
import SignUpForm from './partials/signUpForm';

export default function SignUp() {
  const { setTitle } = useTitle();
  setTitle('Sign Up');
  return (
    <LoginLayout>
      <SignUpForm />
    </LoginLayout>
  );
}
