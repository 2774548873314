import { Container, Typography } from '@mui/material';
import React from 'react';
import ContactForm from './contactForm';
import { useTitle } from '../../hoc/titleProvider';

function ContactUs() {
  const { setTitle } = useTitle();
  setTitle('Contact Us');

  return (
    <>
      <Container>
        <Typography variant="h1">Contact Us</Typography>
        <ContactForm />
      </Container>
    </>
  );
}

export default ContactUs;
