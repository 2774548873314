import React from 'react';
import { useTitle } from '../../hoc/titleProvider';
import LoginLayout from './loginLayout';
import ForgotPasswordForm from './partials/forgotPasswordForm';

function ForgotPassword() {
  const { setTitle } = useTitle();
  setTitle('Forgot Password');

  return (
    <LoginLayout>
      <ForgotPasswordForm />
    </LoginLayout>
  );
}

export default ForgotPassword;
